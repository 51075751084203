import type { Ref } from 'vue'

const tenantBaseUrl = (publicId: Ref<string> | string) => {
  const config = useRuntimeConfig()
  return `https://${toValue(publicId)}.${config.public.spaHost}`
}

const tenantSigninUrl = (publicId: Ref<string> | string) => {
  return `${tenantBaseUrl(publicId)}/signin`
}

const slackBaseUrl = () => {
  return tenantBaseUrl('slack')
}

const boxBaseUrl = () => {
  return tenantBaseUrl('box')
}

const revisionTxtUrl = () => {
  return `${tenantBaseUrl('edge')}/revision.txt`
}

const tenantSelectPageUrl = () => {
  return `${tenantBaseUrl('app')}/select`
}

export const useUrls = () => {
  return {
    termsPageUrl: () => 'https://kickflow.com/terms',
    privacyPageUrl: () => 'https://kickflow.com/privacy',
    kickflowComUrl: () => 'https://kickflow.com',
    tenantSelectPageUrl,
    tenantBaseUrl,
    slackBaseUrl,
    boxBaseUrl,
    revisionTxtUrl,
    tenantSigninUrl,
  }
}
