import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { Label } from '~/types/label'
import type { Ticket } from '~/types/ticket'

/**
 * Label API
 */
export class KickflowLabelApi extends KickflowApi {
  async getLabels(
    paging: PagingQuery,
    q: string | null
  ): Promise<PagingList<Label>> {
    const queryParams = paging.toParams()
    if (q) {
      queryParams.q = q
    }
    return await this.getPagingList('/labels', queryParams)
  }

  async createLabel(params: {
    name: string
    description: string | null
    color: string
  }): Promise<Label> {
    return await this.post('/labels', params)
  }

  async updateLabel(
    label: Label,
    params: {
      name: string
      description: string | null
      color: string
    }
  ): Promise<Label> {
    return await this.patch(`/labels/${label.id}`, params)
  }

  async deleteLabel(label: Label) {
    return await this.delete(`/labels/${label.id}`)
  }

  async addLabel(ticket: Ticket, label: Label) {
    const params = {
      labelId: label.id,
    }
    return await this.post(`/tickets/${ticket.id}/labels`, params)
  }

  async removeLabel(ticket: Ticket, label: Label) {
    return await this.delete(`/tickets/${ticket.id}/labels/${label.id}`)
  }
}
