import { FetchError } from 'ofetch'
import { defineStore } from 'pinia'
import type { TranslateResult } from 'vue-i18n'

import type { UnreadCountData } from '~/types/notification'
import type { Breadcrumb } from '~/types/pages/components'
import type { Progress } from '~/types/progress'
import type { PricingPlanPublicId } from '~/types/subscription'

interface CommonUiState {
  /**
   * 各画面のヘッダー
   */
  header: {
    title: TranslateResult | null
    subtitle: TranslateResult | null
    subtitleHtml: boolean
    breadcrumbs: Breadcrumb[]
    plan: PricingPlanPublicId | null
  }
  /**
   * スナックバー
   */
  snackbar: {
    color: 'success' | 'error'
    message: string | null
    shown: boolean
  }
  /**
   * CSVエクスポートなどの進捗用スナックバー
   */
  progresses: Progress[]
  /**
   * 承認リクエストのバッジ
   */
  taskCount: number
  /**
   * 通知バッジ
   */
  notificationCount: number
  /**
   * 強制アップデート
   */
  reload: boolean
}

export const useCommonUiStore = defineStore('commonUi', {
  state: (): CommonUiState => {
    return {
      header: {
        title: null,
        subtitle: null,
        subtitleHtml: false,
        breadcrumbs: [],
        plan: null,
      },
      snackbar: {
        color: 'success',
        message: null,
        shown: false,
      },
      progresses: [],
      taskCount: 0,
      notificationCount: 0,
      reload: false,
    }
  },
  getters: {},
  actions: {
    setHeader(
      title: TranslateResult,
      subtitle: TranslateResult | null,
      breadcrumbs: Breadcrumb[],
      plan: PricingPlanPublicId | null
    ) {
      this.header.title = title
      this.header.subtitle = subtitle
      this.header.subtitleHtml = false
      this.header.breadcrumbs = breadcrumbs
      this.header.plan = plan
    },
    setHtmlHeader(
      title: TranslateResult,
      subtitle: TranslateResult | null,
      breadcrumbs: Breadcrumb[],
      plan: PricingPlanPublicId | null
    ) {
      this.header.title = title
      this.header.subtitle = subtitle
      this.header.subtitleHtml = true
      this.header.breadcrumbs = breadcrumbs
      this.header.plan = plan
    },
    showSnackbar(message: string) {
      this.snackbar.color = 'success'
      this.snackbar.message = message
      this.snackbar.shown = true
    },
    showErrorSnackbar(e: any) {
      this.snackbar.color = 'error'
      if (e instanceof FetchError) {
        this.snackbar.message =
          e.response?._data?.message ||
          '通信に失敗しました。ネットワーク接続を確認してください。'
      } else if (e instanceof Error) {
        this.snackbar.message = e.message || 'エラーが発生しました'
      } else if (typeof e === 'string') {
        this.snackbar.message = e
      } else {
        this.snackbar.message = 'エラーが発生しました'
      }
      this.snackbar.shown = true
    },
    hideSnackbar() {
      this.snackbar.shown = false
    },
    addProgressSnackbar(progress: Progress) {
      this.progresses.push(progress)
    },
    replaceProgressSnackbar(progress: Progress) {
      const index = this.progresses.findIndex((p) => p.id === progress.id)
      this.progresses.splice(index, 1, progress)
    },
    removeProgressSnackbar(progressId: string) {
      const index = this.progresses.findIndex((p) => p.id === progressId)
      this.progresses.splice(index, 1)
    },
    setTaskCount(taskCount: number) {
      this.taskCount = taskCount
    },
    setNotificationCount(notificationCount: number) {
      this.notificationCount = notificationCount
    },
    async refreshTaskAlert() {
      try {
        const nuxtApp = useNuxtApp()
        const data = await nuxtApp.$kickflowTicketApi.getTaskCount()
        this.setTaskCount(data.taskCount)
      } catch {
        // do nothing
      }
    },
    async refreshNotificationAlert() {
      try {
        const nuxtApp = useNuxtApp()
        const data: UnreadCountData =
          await nuxtApp.$kickflowNotificationApi.getUnreadCount()
        this.setNotificationCount(data.unreadCount)
      } catch {
        // do nothing
      }
    },
    setReload(reload: boolean) {
      this.reload = reload
    },
  },
})
