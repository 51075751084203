import { TinyEmitter } from 'tiny-emitter'
const emitter = new TinyEmitter()

const ZENDESK_KEY = 'b43ee46a-f6bb-4e1f-96d4-a50e66b03696'

// window.zEを宣言しておく
declare global {
  interface Window {
    zE: any
  }
}

// https://github.com/dansmaculotte/vue-zendesk/blob/master/src/index.js
export default defineNuxtPlugin((_nuxtApp) => {
  const root: any = {}

  root.$on = (event: string, callback: (...args: any[]) => any, ctx?: any) =>
    emitter.on(event, callback, ctx)
  root.$once = (event: string, callback: (...args: any[]) => any, ctx?: any) =>
    emitter.once(event, callback, ctx)
  root.$emit = (event: string, ...args: any[]) => emitter.emit(event, ...args)
  root.$off = (event: string, callback?: (...args: any[]) => any) =>
    emitter.off(event, callback)

  let isLoaded = false

  root.isLoaded = () => isLoaded
  root.load = () => {
    if (isLoaded) {
      return
    }

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.id = 'ze-snippet'
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=' + ZENDESK_KEY

    // if (options.nonce) {
    //   script.setAttribute('nonce', options.nonce)
    // }

    delete window.zE
    const first = document.getElementsByTagName('script')[0]
    first.parentNode!.insertBefore(script, first)

    script.onload = (event) => {
      isLoaded = true

      // hide on load
      root.hide()

      root.$emit('loaded', event)

      window.zE('webWidget:on', 'open', () => {
        root.$emit('open')
      })

      window.zE('webWidget:on', 'close', () => {
        root.$emit('close')
      })
    }
  }

  root.load()

  root.hide = () => window.zE('webWidget', 'hide')
  root.show = () => window.zE('webWidget', 'show')
  root.logout = () => window.zE('webWidget', 'logout')
  root.identify = (user: object) => window.zE('webWidget', 'identify', user)
  root.prefill = (user: object) => window.zE('webWidget', 'prefill', user)
  root.setLocale = (locale: string) =>
    window.zE('webWidget', 'setLocale', locale)
  root.updateSettings = (settings: object) =>
    window.zE('webWidget', 'updateSettings', settings)
  root.clear = () => window.zE('webWidget', 'clear')
  root.updatePath = (options: object) =>
    window.zE('updatePath', 'clear', options)
  root.toggle = () => window.zE('webWidget', 'toggle')
  root.reset = () => window.zE('webWidget', 'reset')
  root.close = () => window.zE('webWidget', 'close')
  root.open = () => window.zE('webWidget', 'open')

  Object.defineProperty(root, 'zE', {
    get: function get() {
      return window.zE
    },
  })

  return {
    provide: {
      zendesk: root,
    },
  }
})
