// Auth0のインスタンスにコンポーネント外からでもアクセスできるようにするためのハック
// Vueコンポーネントの外からAuth0を使いたいときは、useAuth0ではなくこのcomposableを使う
// https://github.com/auth0/auth0-vue/issues/99#issuecomment-1099704276

import type { NuxtApp } from '#app'

export const useAuth0Plugin = () => {
  const nuxtApp = useNuxtApp() as NuxtApp
  return nuxtApp.$auth0
}
