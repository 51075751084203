import { KickflowApi } from '~/api/kickflowApi'
import type { StatsApprovalsData, StatsData } from '~/types/stats'

/**
 * Stats API
 */
export class KickflowStatsApi extends KickflowApi {
  async getTicketCountsStats(
    startDate: string,
    endDate: string,
    userId: string | null,
    workflowId: string | null
  ): Promise<StatsData[]> {
    const params = {
      startDate,
      endDate,
      userId,
      workflowId,
    }
    return await this.get('/stats/ticketCounts', params)
  }

  async getTicketApprovalsStats(
    startDate: string,
    endDate: string,
    userId: string | null
  ): Promise<StatsApprovalsData[]> {
    const params = {
      startDate,
      endDate,
      userId,
    }
    return await this.get('/stats/ticketApprovals', params)
  }
}
