import MarkdownIt from 'markdown-it'
import { full as emoji } from 'markdown-it-emoji'

export default defineNuxtPlugin((_nuxtApp) => {
  const markdown = new MarkdownIt({
    linkify: true,
    breaks: true,
    html: true,
    typographer: true,
    langPrefix: 'language-',
    quotes: '“”‘’',
  }).use(emoji)
  return {
    provide: {
      md: markdown,
    },
  }
})
