import { KickflowApi } from '~/api/kickflowApi'
import type { ExternalApiSetting } from '~/types/formField'

export interface ExternalApiResult {
  status: number
  body: string | null
}

/**
 * External API setting API
 */
export class KickflowExternalApi extends KickflowApi {
  async run(
    setting: ExternalApiSetting,
    scope: { [key: string]: any }
  ): Promise<ExternalApiResult> {
    // Rails側でリクエストボディのキー(JSONのキー)をスネークケースに変換しているのでscopeはJSONとして渡す
    // そのまま渡すと変数名が正しく復元できない場合がある
    // 例： $TestTest という変数名の場合、 $test_test というキーになる($の後が大文字だったことが消えるため復元できない)
    const params = {
      scope: JSON.stringify(scope),
    }
    return await this.post(`/externalApiSettings/${setting.id}/run`, params)
  }
}
