import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

export default defineNuxtPlugin((_nuxtApp) => {
  // day.jsの初期設定
  dayjs.extend(timezone)
  dayjs.extend(utc)
  dayjs.extend(relativeTime)

  return {}
})
