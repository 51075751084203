import { KickflowApi } from '~/api/kickflowApi'
import type { CloudSignDocumentBody } from '~/types/body/cloudSignBody'
import type {
  CloudSignDocument,
  CloudSignResponseDocumentAttribute,
} from '~/types/cloudSign'

export class KickflowCloudSignApi extends KickflowApi {
  async connect(params: { clientId: string; sandbox: boolean }) {
    return await this.post('/cloudSign', params)
  }

  async disconnect() {
    return await this.delete('/cloudSign')
  }

  async getDocuments(): Promise<CloudSignDocument[]> {
    return await this.get('/cloudSign/documents')
  }

  async getDocument(
    documentId: string
  ): Promise<CloudSignResponseDocumentAttribute> {
    return await this.get(`/cloudSign/documents/${documentId}`)
  }

  async createDocument(
    body: CloudSignDocumentBody
  ): Promise<CloudSignDocument> {
    return await this.post('/cloudSign/documents', body)
  }

  async refreshDocument(documentId: string) {
    return await this.post(`/cloudSign/documents/${documentId}/refresh`)
  }

  async getFile(
    documentId: string,
    fileId: string,
    filename: string
  ): Promise<any> {
    const response = await this.getBlob(
      `/cloudSign/documents/${documentId}/files/${fileId}`
    )
    downloadPDF(response, filename)
  }
}
