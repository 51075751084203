import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { GsuiteSyncResult } from '~/types/gsuite'
import type { GsuiteIntegration } from '~/types/tenant'

export class KickflowGsuiteApi extends KickflowApi {
  async getGsuiteIntegration(): Promise<GsuiteIntegration> {
    return await this.get('/gsuite')
  }

  async create(params: { domain: string | null; customerId: string | null }) {
    return await this.post('/gsuite', params)
  }

  async update(params: { domain: string | null; customerId: string | null }) {
    return await this.patch('/gsuite', params)
  }

  async destroy() {
    return await this.delete('/gsuite')
  }

  async sync() {
    return await this.post('/gsuite/sync')
  }

  async getResults(paging: PagingQuery): Promise<PagingList<GsuiteSyncResult>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/gsuite/results', queryParams)
  }
}
