import { KickflowApi } from '~/api/kickflowApi'

export class KickflowFcmTokenApi extends KickflowApi {
  async sendFcmToken(token: string, userAgent: string | null) {
    const params = {
      token,
      userAgent,
    }
    return await this.post('/fcmTokens', params)
  }
}
