import * as Sentry from '@sentry/vue'
import { Buffer } from 'buffer'

import type { NuxtApp } from '#app'

export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const nuxtApp = useNuxtApp() as NuxtApp
  const consola = useConsola()
  const config = useRuntimeConfig()
  const { revisionTxtUrl } = useUrls()

  consola.debug('[revision] start')
  const elem = document.getElementsByName(
    'kickflow-revision'
  )[0] as HTMLMetaElement
  const localRevision = parseInt(elem.content)
  consola.debug('local revision is ' + localRevision)
  if (localRevision) {
    Sentry.setContext('revision', {
      revision: localRevision,
    })

    try {
      // memo:
      // リビジョンファイルはテナントに依存しないCDNのURLから取得する
      // CDNで一部のテナントでのみ古いリビジョンがキャッシュされたままになったことがあるため
      const url = revisionTxtUrl()
      const headers: { [key: string]: string | undefined } = {}
      if (!!config.public.basicUser || !!config.public.basicPass) {
        headers.Authorization = `Basic ${Buffer.from(
          `${config.public.basicUser}:${config.public.basicPass}`
        ).toString('base64')}`
      }
      const response = await nuxtApp.$httpClient.getRaw(url, {}, headers)
      const remoteRevision = parseInt(response._data)
      if (localRevision !== remoteRevision) {
        nuxtApp.$consola.debug('Need to refresh')
        const commonUiStore = useCommonUiStore()
        commonUiStore.setReload(true)
      }
    } catch (e) {
      consola.debug(e)
    }
  }
  consola.debug('[revision] end')
})
