import { createConsola } from 'consola'

export default defineNuxtPlugin((_nuxtApp) => {
  const consola = createConsola({
    // 本番ではエラーのみ、それ以外ではdebug以上
    level: process.env.NODE_ENV === 'production' ? 0 : 4,
  })
  return {
    provide: {
      consola,
    },
  }
})
