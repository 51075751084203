import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type {
  TeamCreateBody,
  TeamInfoBody,
  TeamParentBody,
} from '~/types/body/teamBody'
import type { Encoding } from '~/types/encoding'
import type { Grade } from '~/types/grade'
import type { Progress } from '~/types/progress'
import type { Route } from '~/types/route'
import type {
  DetailedTeam,
  OrganizationChart,
  Team,
  TeamWithOrganization,
} from '~/types/team'
import type { MemberUser, User } from '~/types/user'
import type { Workflow } from '~/types/workflow'

/**
 * 組織図API
 */
export class KickflowOrganizationChartApi extends KickflowApi {
  // org

  async getOrganizationCharts(
    paging: PagingQuery,
    q: string | null | undefined
  ): Promise<PagingList<OrganizationChart>> {
    const queryParams = paging.toParams()
    if (q) {
      queryParams.q = q
    }
    return await this.getPagingList('/organizationCharts', queryParams)
  }

  async getCurrentOrganizationChart(): Promise<OrganizationChart> {
    return await this.get('/organizationChart')
  }

  async getOrganizationChart(id: string): Promise<OrganizationChart> {
    return await this.get(`/organizationCharts/${id}`)
  }

  async createOrganizationChart(name: string): Promise<OrganizationChart> {
    const params = {
      name,
    }
    return await this.post('/organizationCharts', params)
  }

  async updateOrganizationChart(
    org: OrganizationChart,
    name: string
  ): Promise<OrganizationChart> {
    const params = {
      name,
    }
    return await this.put(`/organizationCharts/${org.id}`, params)
  }

  async deleteOrganizationChart(org: OrganizationChart) {
    return await this.delete(`/organizationCharts/${org.id}`)
  }

  async activateOrganizationChart(org: OrganizationChart): Promise<Progress> {
    return await this.post(`/organizationCharts/${org.id}/activate`)
  }

  async createActivationPlan(
    org: OrganizationChart,
    dueOn: string
  ): Promise<OrganizationChart> {
    const params = {
      dueOn,
    }
    return await this.post(`/organizationCharts/${org.id}/plan`, params)
  }

  async deleteActivationPlan(
    org: OrganizationChart
  ): Promise<OrganizationChart> {
    return await this.delete(`/organizationCharts/${org.id}/plan`)
  }

  async copyOrganizationChart(org: OrganizationChart): Promise<Progress> {
    return await this.post(`/organizationCharts/${org.id}/copy`)
  }

  async getTeamsToBeDeleted(
    paging: PagingQuery,
    id: string
  ): Promise<PagingList<TeamWithOrganization>> {
    const queryParams = paging.toParams()
    return await this.getPagingList(
      `/organizationCharts/teamsToBeDeleted?organizationChartId=${id}`,
      queryParams
    )
  }

  // teams

  async getTeams(
    paging: PagingQuery,
    orgId: string,
    parentId: string | null
  ): Promise<PagingList<Team>> {
    const params = paging.toParams()
    if (parentId) {
      params.parentId = parentId
    }
    return await this.getPagingList(
      `/organizationCharts/${orgId}/teams`,
      params
    )
  }

  async searchTeams(
    paging: PagingQuery,
    orgId: string,
    q: string
  ): Promise<PagingList<Team>> {
    const params = paging.toParams()
    params.q = q
    return await this.getPagingList(
      `/organizationCharts/${orgId}/teams/search`,
      params
    )
  }

  async getTeam(org: OrganizationChart, id: string): Promise<DetailedTeam> {
    return await this.get(`/organizationCharts/${org.id}/teams/${id}`)
  }

  async createTeam(
    org: OrganizationChart,
    params: TeamCreateBody
  ): Promise<DetailedTeam> {
    return await this.post(`/organizationCharts/${org.id}/teams`, params)
  }

  async updateTeamInfo(
    org: OrganizationChart,
    team: Team,
    params: TeamInfoBody
  ): Promise<DetailedTeam> {
    return await this.put(
      `/organizationCharts/${org.id}/teams/${team.id}`,
      params
    )
  }

  async updateTeamParent(
    org: OrganizationChart,
    team: Team,
    params: TeamParentBody
  ): Promise<DetailedTeam> {
    return await this.put(
      `/organizationCharts/${org.id}/teams/${team.id}`,
      params
    )
  }

  async deleteTeam(org: OrganizationChart, team: Team, descendants: boolean) {
    return await this.delete(
      `/organizationCharts/${org.id}/teams/${team.id}?descendants=${descendants}`
    )
  }

  async importTeams(
    org: OrganizationChart,
    blobSignedId: string
  ): Promise<Progress> {
    return await this.post(`/organizationCharts/${org.id}/teams/import`, {
      file: blobSignedId,
    })
  }

  async exportTeams(
    org: OrganizationChart,
    encoding: Encoding
  ): Promise<Progress> {
    const params = {
      encoding,
    }
    return await this.get(`/organizationCharts/${org.id}/teams/csv`, params)
  }

  async lookupByCode(org: OrganizationChart, code: string[]): Promise<Team[]> {
    const params = { code }
    return await this.get(
      `/organizationCharts/${org.id}/teams/lookupByCode`,
      params
    )
  }

  // members

  async getMembers(
    paging: PagingQuery,
    org: OrganizationChart,
    team: Team
  ): Promise<PagingList<MemberUser>> {
    const queryParams = paging.toParams()
    return await this.getPagingList(
      `/organizationCharts/${org.id}/teams/${team.id}/memberships`,
      queryParams
    )
  }

  async createMember(org: OrganizationChart, team: Team, user: User) {
    const data = {
      userId: user.id,
    }
    return await this.post(
      `/organizationCharts/${org.id}/teams/${team.id}/memberships`,
      data
    )
  }

  async createMembers(
    org: OrganizationChart,
    team: Team,
    users: User[],
    grades: Grade[],
    leader: boolean
  ) {
    const data = {
      users: users.map((user) => {
        return {
          id: user.id,
          leader,
          gradeIds: grades.map((g) => g.id),
        }
      }),
    }
    return await this.post(
      `/organizationCharts/${org.id}/teams/${team.id}/memberships`,
      data
    )
  }

  async updateMember(
    org: OrganizationChart,
    team: Team,
    user: User,
    grades: Grade[],
    leader: boolean
  ) {
    const data = {
      gradeIds: grades.map((grade) => grade.id),
      leader,
    }
    return await this.patch(
      `/organizationCharts/${org.id}/teams/${team.id}/memberships/${user.id}`,
      data
    )
  }

  async deleteMember(org: OrganizationChart, team: Team, userId: string) {
    return await this.delete(
      `/organizationCharts/${org.id}/teams/${team.id}/memberships/${userId}`
    )
  }

  async importMemberships(
    org: OrganizationChart,
    blobSignedId: string
  ): Promise<Progress> {
    return await this.post(`/organizationCharts/${org.id}/memberships/import`, {
      file: blobSignedId,
    })
  }

  async exportMemberships(
    org: OrganizationChart,
    encoding: Encoding
  ): Promise<Progress> {
    const url = `/organizationCharts/${org.id}/memberships/csv`
    const params = { encoding }
    return await this.get(url, params)
  }

  // route

  async getReferringRoutes(
    paging: PagingQuery,
    org: OrganizationChart,
    team: Team
  ): Promise<PagingList<Route>> {
    const queryParams = paging.toParams()
    return await this.getPagingList(
      `/organizationCharts/${org.id}/teams/${team.id}/routes`,
      queryParams
    )
  }

  async getReferringWorkflows(
    paging: PagingQuery,
    org: OrganizationChart,
    team: Team
  ): Promise<PagingList<Workflow>> {
    const queryParams = paging.toParams()
    return await this.getPagingList(
      `/organizationCharts/${org.id}/teams/${team.id}/workflows`,
      queryParams
    )
  }
}
