import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type {
  AllowedInvitationDomain,
  AllowedIpAddress,
  AuthenticationMethod,
  CurrentIp,
  IpAddressRestriction,
  SamlInfo,
} from '~/types/security'
import type { SsoSetting } from '~/types/ssoSetting'

/**
 * Security API
 */
export class KickflowSecurityApi extends KickflowApi {
  /**
   * 認証方式を更新する
   * @param params
   */
  async updateAuthenticationMethod(
    params: AuthenticationMethod
  ): Promise<AuthenticationMethod> {
    return await this.patch('/authenticationMethod', params)
  }

  /**
   * SAML connectionを取得する
   */
  async getSso(): Promise<SamlInfo> {
    return await this.get('/sso')
  }

  /**
   * SAMLメタデータをアップロードする
   * @param metadata SAMLメタデータXMLの中身
   */
  async createSsoByMetadata(metadata: string) {
    return await this.post('/sso', { metadata })
  }

  /**
   * SAML X509署名証明書をアップロードする
   * @param params X509署名証明書の中身
   */
  async createSsoByCrt(params: {
    crt: string
    signInEndpoint: string
    signOutEndpoint: string
  }) {
    return await this.post('/sso', params)
  }

  /**
   * SAMLメタデータをアップロードする
   * @param metadata SAMLメタデータXMLの中身
   */
  async updateSsoByMetadata(metadata: string) {
    return await this.patch('/sso', { metadata })
  }

  /**
   * SAML X509署名証明書をアップロードする
   * @param params X509署名証明書の中身
   */
  async updateSsoByCrt(params: {
    crt: string
    signInEndpoint: string
    signOutEndpoint: string
  }) {
    return await this.patch('/sso', params)
  }

  /**
   * SAML設定を削除する
   */
  async deleteSsoMetadata() {
    return await this.delete('/sso')
  }

  async getSsoSetting(): Promise<SsoSetting> {
    return await this.get('/ssoSetting')
  }

  async updateSsoSetting(params: {
    jitProvisioningEnabled: boolean
  }): Promise<SsoSetting> {
    return await this.patch('/ssoSetting', params)
  }

  async getAllowedIpAddresses(
    paging: PagingQuery
  ): Promise<PagingList<AllowedIpAddress>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/allowedIpAddresses', queryParams)
  }

  async createAllowedIpAddress(ip: string) {
    return await this.post('/allowedIpAddresses', { ip })
  }

  async deleteAllowedIpAddress(ip: AllowedIpAddress) {
    return await this.delete(`/allowedIpAddresses/${ip.id}`)
  }

  async getCurrentIp(): Promise<CurrentIp> {
    return await this.get('/allowedIpAddresses/current')
  }

  async getIpAddressRestriction(): Promise<IpAddressRestriction> {
    return await this.get('/ipAddressRestriction')
  }

  async enableIpAddressRestriction() {
    return await this.post('/ipAddressRestriction')
  }

  async disableIpAddressRestriction() {
    return await this.delete('/ipAddressRestriction')
  }

  async getAllowedInvitationDomains(
    paging: PagingQuery
  ): Promise<PagingList<AllowedInvitationDomain>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/allowedInvitationDomains', queryParams)
  }

  async createAllowedInvitationDomain(domain: string) {
    return await this.post('/allowedInvitationDomains', { domain })
  }

  async deleteAllowedInvitationDomain(domain: AllowedInvitationDomain) {
    return await this.delete(`/allowedInvitationDomains/${domain.id}`)
  }
}
