import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { RouteBody } from '~/types/body/routeBody'
import type { Encoding } from '~/types/encoding'
import type { Progress } from '~/types/progress'
import type { Route, RouteStatus } from '~/types/route'
import type { TicketRouteResult } from '~/types/ticketRouteResult'
import type { Workflow } from '~/types/workflow'

/**
 * 経路API
 */
export class KickflowRouteApi extends KickflowApi {
  async getRoutes(
    paging: PagingQuery,
    folderId?: string[],
    writable?: boolean,
    q?: string | null,
    status?: RouteStatus[]
  ): Promise<PagingList<Route>> {
    const queryParams = paging.toParams()
    if (folderId) {
      queryParams.folderId = folderId
    }
    if (writable !== undefined) {
      queryParams.writable = writable
    }
    if (q) {
      queryParams.q = q
    }
    if (status) {
      queryParams.status = status
    }
    return await this.getPagingList('/routes', queryParams)
  }

  async getRoute(id: string): Promise<Route> {
    return await this.get(`/routes/${id}`)
  }

  async lookupByCode(code: string[]): Promise<Route[]> {
    const params = { code }
    return await this.get('/routes/lookupByCode', params)
  }

  async verifyRoute(
    route: Route,
    params: { userId: string; teamId: string }
  ): Promise<TicketRouteResult> {
    return await this.post(`/routes/${route.id}/verify`, params)
  }

  async createRoute(params: RouteBody): Promise<Route> {
    return await this.post('/routes', params)
  }

  async updateRoute(route: Route, params: RouteBody): Promise<Route> {
    return await this.put(`/routes/${route.id}`, params)
  }

  async bulkUpdateRoute(routeIds: string[], folderId: string | null) {
    const params = {
      routeId: routeIds,
      folderId,
    }
    return await this.patch('/routes/bulkUpdate', params)
  }

  async deleteRoute(route: Route) {
    return await this.delete(`/routes/${route.id}`)
  }

  async bulkDestroyRoute(routeIds: string[]) {
    const params = {
      routeId: routeIds,
    }
    return await this.delete('/routes/bulkDestroy', params)
  }

  async copyRoute(route: Route): Promise<Route> {
    return await this.post(`/routes/${route.id}/copy`)
  }

  async importCsv(signedId: string): Promise<Progress> {
    return await this.post('/routes/import', { file: signedId })
  }

  async exportRoutes(encoding: Encoding): Promise<Progress> {
    const params = {
      encoding,
    }
    return await this.get('/routes/csv', params)
  }

  async getRouteVersions(
    route: Route,
    paging: PagingQuery
  ): Promise<PagingList<Route>> {
    const queryParams = paging.toParams()
    return await this.getPagingList(`/routes/${route.id}/versions`, queryParams)
  }

  async getRouteVersion(id: string, versionNumber: string): Promise<Route> {
    return await this.get(`/routes/${id}/versions/${versionNumber}`)
  }

  async getReferringWorkflows(
    id: string,
    pagingQuery: PagingQuery
  ): Promise<PagingList<Workflow>> {
    const params = pagingQuery.toParams()
    return await this.getPagingList(`/routes/${id}/workflows`, params)
  }
}
