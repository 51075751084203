import { KickflowApi } from '~/api/kickflowApi'
import type { SearchCondition } from '~/types/searchCondition'
import type { SearchType } from '~/types/state/ticketSearchParams'

export class KickflowSearchConditionApi extends KickflowApi {
  async getSearchConditions(
    searchType: SearchType
  ): Promise<SearchCondition[]> {
    return await this.get(`/searchConditions?search_type=${searchType}`)
  }

  async createSearchCondition(
    searchType: SearchType,
    name: string,
    searchParams: any
  ): Promise<SearchCondition> {
    return await this.post('/searchConditions', {
      name,
      searchType,
      condition: searchParams,
    })
  }

  async updateSearchCondition(
    id: string,
    searchParams: any
  ): Promise<SearchCondition> {
    return await this.patch(`/searchConditions/${id}`, {
      condition: searchParams,
    })
  }

  async updateSearchConditionName(
    id: string,
    name: string | null | undefined
  ): Promise<SearchCondition> {
    return await this.patch(`/searchConditions/${id}`, {
      name,
    })
  }

  async deleteSearchCondition(searchConditionId: string): Promise<void> {
    return await this.delete(`/searchConditions/${searchConditionId}`)
  }
}
