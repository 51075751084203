import { KickflowApi } from '~/api/kickflowApi'
import type { Authenticator, RecoveryCodeResponse } from '~/types/mfa'

/**
 * MFA API
 */
export class KickflowMfaApi extends KickflowApi {
  async getAuthenticators(): Promise<Authenticator[]> {
    return await this.get('/mfa/authenticators')
  }

  async createAuthenticator(
    type: 'totp',
    totpSecret: string
  ): Promise<RecoveryCodeResponse> {
    return await this.post('/mfa/authenticators', { type, totpSecret })
  }

  async deleteAuthenticators() {
    return await this.delete('/mfa/authenticators')
  }

  async refreshRecoveryCode(): Promise<RecoveryCodeResponse> {
    return await this.post('/mfa/authenticators/recoveryCode')
  }
}
