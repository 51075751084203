/**
 * PDFをダウンロードする
 * @param blob
 * @param fileName
 */
export function downloadPDF(blob: Blob, fileName: string) {
  downloadBlob(blob, 'application/pdf', fileName)
}

/**
 * ファイルをダウンロードする
 * @param blob
 * @param type
 * @param fileName
 */
export function downloadBlob(blob: Blob, type: string, fileName: string) {
  // @ts-expect-error IE、Edge の仕様に依存しているコードであり、不測のエラーが発生する可能性があるため @ts-expect-error を付与
  if (window.navigator.msSaveOrOpenBlob) {
    // for IE,Edge
    // @ts-expect-error IE、Edge の仕様に依存しているコードであり、不測のエラーが発生する可能性があるため @ts-expect-error を付与
    window.navigator.msSaveOrOpenBlob(blob, fileName)
  } else {
    // for chrome, firefox
    const url = URL.createObjectURL(new Blob([blob], { type }))
    const linkEl = document.createElement('a')
    linkEl.href = url
    linkEl.setAttribute('download', fileName)
    document.body.appendChild(linkEl)
    linkEl.click()

    URL.revokeObjectURL(url)
    linkEl.parentNode!.removeChild(linkEl)
  }
}
