import type { Blob } from 'activestorage'
import { DirectUpload } from 'activestorage'
import { Buffer } from 'buffer'

import type { NuxtApp } from '#app'
import { useAuth0Plugin } from '~/composables/auth0'

/**
 * ファイルアップローダークラス
 * startUploadを呼ぶ度に、内部ではActiveStorageのDirectUploadを作成します
 */
export class Uploader {
  nuxtApp: NuxtApp
  url?: string
  headers: { [s: string]: string } = {}

  constructor(nuxtApp: NuxtApp) {
    this.nuxtApp = nuxtApp
    if (
      !!nuxtApp.$config.public.basicUser ||
      !!nuxtApp.$config.public.basicPass
    ) {
      const token = Buffer.from(
        `${nuxtApp.$config.public.basicUser}:${nuxtApp.$config.public.basicPass}`
      ).toString('base64')
      this.headers.Authorization = 'Basic ' + token
    }
  }

  /**
   * ファイルをアップロードする
   * @param file
   */
  async startUpload(file: File): Promise<Blob> {
    if (!this.url) {
      throw new Error('URL is not initialized')
    }

    await this.reloadAccessToken()
    return new Promise((resolve, reject) => {
      const upload = new DirectUpload(file, this.url!, this)
      upload.create((error, blob) => {
        if (error) {
          this.nuxtApp.$consola.debug('upload error')
          this.nuxtApp.$consola.debug(error)
          reject(error)
        } else {
          this.nuxtApp.$consola.debug('upload ok')
          this.nuxtApp.$consola.debug(blob)
          resolve(blob)
        }
      })
    })
  }

  /**
   * APIのURLを設定する
   * @param url
   */
  setApiBaseUrl(url: string) {
    this.url = `${url}/api/v1/activeStorage/directUploads`
  }

  /**
   * ヘッダーを追加する
   * @param key
   * @param value
   */
  setHeader(key: string, value: string) {
    this.headers[key] = value
  }

  directUploadWillCreateBlobWithXHR(request: XMLHttpRequest) {
    this.addHeadersToXHR(request)
  }

  directUploadWillStoreFileWithXHR(request: XMLHttpRequest) {
    request.upload.addEventListener('progress', (event) =>
      this.directUploadDidProgress(event)
    )
  }

  directUploadDidProgress(_event: ProgressEvent<XMLHttpRequestEventTarget>) {
    // do something
  }

  private addHeadersToXHR(xhr: XMLHttpRequest) {
    for (const key in this.headers) {
      xhr.setRequestHeader(key, this.headers[key])
    }
  }

  /**
   * アクセストークンを再取得する
   * アクセストークンが失効しているがセッションタイムアウト前であれば、ここでアクセストークンが再取得される
   * @private
   */
  private async reloadAccessToken() {
    try {
      const auth0 = useAuth0Plugin()
      const token = await auth0.getAccessTokenSilently()
      this.setHeader('X-Authorization', 'Bearer ' + token)
    } catch {
      // do nothing
    }
  }
}

export default defineNuxtPlugin(async (nuxtApp) => {
  return {
    provide: {
      uploader: new Uploader(nuxtApp as NuxtApp),
    },
  }
})
