import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { MasterBody, MasterItemBody } from '~/types/body/masterBody'
import type { Encoding } from '~/types/encoding'
import type { Master, MasterBase, MasterItem } from '~/types/master'
import type { Progress } from '~/types/progress'
import type { Workflow } from '~/types/workflow'

/**
 * マスタAPI
 */
export class KickflowMasterApi extends KickflowApi {
  async getMasters(paging: PagingQuery): Promise<PagingList<Master>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/generalMasters', queryParams)
  }

  async getMaster(id: string): Promise<Master> {
    return await this.get(`/generalMasters/${id}`)
  }

  async createMaster(params: MasterBody): Promise<Master> {
    return await this.post('/generalMasters', params)
  }

  async updateMaster(master: MasterBase, params: MasterBody): Promise<Master> {
    return await this.put(`/generalMasters/${master.id}`, params)
  }

  async deleteMaster(master: MasterBase) {
    return await this.delete(`/generalMasters/${master.id}`)
  }

  async getManageableMasters(paging: PagingQuery): Promise<PagingList<Master>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/generalMasters/manageable', queryParams)
  }

  // items

  async getItems(
    paging: PagingQuery,
    master: MasterBase,
    name: string | null
  ): Promise<PagingList<MasterItem>> {
    const queryParams = paging.toParams()
    if (name) {
      queryParams.name = name
    }
    return await this.getPagingList(
      `/generalMasters/${master.id}/items`,
      queryParams
    )
  }

  async searchItemsByQuery(
    paging: PagingQuery,
    master: MasterBase,
    excludeOutOfDate: boolean,
    q?: string | null
  ): Promise<PagingList<MasterItem>> {
    const queryParams = paging.toParams()
    if (q) {
      queryParams.q = q
    }
    queryParams.excludeOutOfDate = excludeOutOfDate
    return await this.getPagingList(
      `/generalMasters/${master.id}/items/search`,
      queryParams
    )
  }

  async searchItemsByParams(
    paging: PagingQuery,
    master: MasterBase,
    params: { [s: string]: string | null },
    excludeOutOfDate: boolean
  ): Promise<PagingList<MasterItem>> {
    const queryParams = paging.toParams()
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        queryParams[key] = params[key]
      }
    })
    queryParams.excludeOutOfDate = excludeOutOfDate
    return await this.getPagingList(
      `/generalMasters/${master.id}/items/search`,
      queryParams
    )
  }

  async createItem(
    master: MasterBase,
    params: MasterItemBody
  ): Promise<MasterItem> {
    return await this.post(`/generalMasters/${master.id}/items`, params)
  }

  async updateItem(
    master: MasterBase,
    item: MasterItem,
    params: MasterItemBody
  ): Promise<MasterItem> {
    return await this.put(
      `/generalMasters/${master.id}/items/${item.id}`,
      params
    )
  }

  async deleteItem(master: MasterBase, item: MasterItem) {
    return await this.delete(`/generalMasters/${master.id}/items/${item.id}`)
  }

  async importItems(master: MasterBase, signedId: string): Promise<Progress> {
    return await this.post(`/generalMasters/${master.id}/items/import`, {
      file: signedId,
    })
  }

  async exportItems(master: MasterBase, encoding: Encoding): Promise<Progress> {
    const params = {
      encoding,
    }
    return await this.get(`/generalMasters/${master.id}/items/csv`, params)
  }

  async lookupByCode(
    master: MasterBase,
    code: string[]
  ): Promise<MasterItem[]> {
    const params = { code }
    return await this.get(
      `/generalMasters/${master.id}/items/lookupByCode`,
      params
    )
  }

  async getReferringWorkflows(
    id: string,
    pagingQuery: PagingQuery
  ): Promise<PagingList<Workflow>> {
    const params = pagingQuery.toParams()
    return await this.getPagingList(`/generalMasters/${id}/workflows`, params)
  }
}
