import type { IdToken } from '@auth0/auth0-spa-js'

import { KickflowApi } from '~/api/kickflowApi'
import type { CurrentUser, UserInvitation } from '~/types/user'

/**
 * サインアップ・サインインAPI
 */
export class KickflowAuthApi extends KickflowApi {
  async getInvitation(invitationId: string): Promise<UserInvitation> {
    return await this.get(`/invitations/${invitationId}`)
  }

  async signUpWithEmailPassword(
    email: string,
    password: string
  ): Promise<CurrentUser> {
    return await this.post('/signUpWithEmailPassword', {
      email,
      password,
    })
  }

  async signUpWithIdToken(token: string, claim: IdToken): Promise<CurrentUser> {
    return await this.post(
      '/signUpWithIdToken',
      {
        idToken: claim.__raw,
      },
      {
        'X-Authorization': 'Bearer ' + token,
      }
    )
  }

  async linkPassword(
    invitationId: string,
    password: string
  ): Promise<CurrentUser> {
    return await this.post('/linkPassword', {
      invitationId,
      password,
    })
  }

  async linkAuth0(
    token: string,
    claim: IdToken,
    invitationId: string
  ): Promise<CurrentUser> {
    return await this.post(
      '/linkAuth0',
      {
        idToken: claim.__raw,
        invitationId,
      },
      {
        'X-Authorization': 'Bearer ' + token,
      }
    )
  }

  async linkAuth0Later(token: string, claim: IdToken): Promise<CurrentUser> {
    return await this.post(
      '/linkAuth0Later',
      {
        idToken: claim.__raw,
      },
      {
        'X-Authorization': 'Bearer ' + token,
      }
    )
  }

  async linkAuth0Saml(token: string, claim: IdToken): Promise<CurrentUser> {
    return await this.post(
      '/linkAuth0Saml',
      {
        idToken: claim.__raw,
      },
      {
        'X-Authorization': 'Bearer ' + token,
      }
    )
  }

  async linkAuth0Assistant(
    token: string,
    claim: IdToken,
    assistantId: string
  ): Promise<CurrentUser> {
    return await this.post(
      '/linkAuth0Assistant',
      {
        idToken: claim.__raw,
        assistantId,
      },
      {
        'X-Authorization': 'Bearer ' + token,
      }
    )
  }
}
