import { KickflowApi } from '~/api/kickflowApi'
import type { MicrosoftTeamsTenant } from '~/types/microsoftTeams'

export class KickflowMicrosoftTeamsApi extends KickflowApi {
  async ping(webhookUrl: string) {
    return await this.post('/microsoftTeams/ping', { webhookUrl })
  }

  async getMicrosoftTeamsTenants(): Promise<MicrosoftTeamsTenant[]> {
    return await this.get('/microsoftTeamsSync')
  }

  async startSync() {
    return await this.get('/microsoftTeamsSync/startSync')
  }

  async endSync(teamsTenantId: string) {
    return await this.get('/microsoftTeamsSync/endSync', { teamsTenantId })
  }

  async callback(params: {
    tenantId: string
    teamsTenantId: string
    errorMessage: string
    type: string
  }) {
    return await this.post('/microsoftTeamsSync/callback', params)
  }

  async progress(teamsTenantId: string) {
    return await this.get('/microsoftTeamsSync/progress', { teamsTenantId })
  }
}
