import type { RuntimeConfig } from 'nuxt/schema'

import type { NuxtApp } from '#app'

/**
 * APIのドメインを返す
 * SPAではテナントごとのサブドメインが付くがRails APIではサブドメインはorigin固定になる
 * CDNを経由すると遅くなるので、必ずOriginを向くようにする
 * @param config Nuxt runtime config
 * @returns {string} e.g. https://origin.kickflow.com or https://origin.lvh.me:3001
 */
function getApiBaseUrl(config: RuntimeConfig) {
  if (!config.public.spaHost || config.public.spaHost.startsWith('lvh.me')) {
    return 'https://origin.lvh.me:3001'
  } else {
    return `https://origin.${config.public.spaHost}`
  }
}

export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const nuxtApp = useNuxtApp() as NuxtApp
  const config = useRuntimeConfig()
  const consola = useConsola()

  consola.debug('[subdomain] start')

  const subdomain = getSubdomain()
  consola.debug('subdomain is ' + subdomain)
  if (!subdomain) {
    throw createError({ statusCode: 404, message: 'ページが見つかりません' })
  }

  // Auth0の初期化
  // if (!$auth0) {
  //   const auth0Client = await nuxtApp.$createAuth0()
  //   initializeAuth0(auth0Client)
  // }

  // HttpClientのベースURLとテナントヘッダをセット
  const baseUrl = getApiBaseUrl(config) + '/api/v1/'
  nuxtApp.$httpClient.setBaseUrl(baseUrl)
  nuxtApp.$httpClient.setHeader('X-Tenant', subdomain)
  consola.debug('baseUrl is ' + baseUrl)

  // sandboxでは明示的にBasic認証ヘッダーを詰める（APIのドメインが異なるため）
  if (
    !!nuxtApp.$config.public.basicUser ||
    !!nuxtApp.$config.public.basicPass
  ) {
    nuxtApp.$httpClient.setBasicAuth(
      nuxtApp.$config.public.basicUser,
      nuxtApp.$config.public.basicPass
    )
  }

  // uploader
  const uploader = nuxtApp.$uploader
  uploader.setApiBaseUrl(getApiBaseUrl(config))
  uploader.setHeader('X-Tenant', subdomain)

  consola.debug('[subdomain] end')
})
