/**
 * 現在のドメインを返す
 * @returns {string}
 */
export const getBaseUrl = () => {
  return (
    location.protocol +
    '//' +
    location.hostname +
    (location.port ? ':' + location.port : '')
  )
}

/**
 * 現在のURLのサブドメインを返す。ただしwwwやoriginの場合はnullを返す
 * 例:
 * kickflow.com => null
 * www.kickflow.com => null
 * origin.kickflow.com => null
 * hoge.origin.kickflow.com => hoge
 * @returns {string|null}
 */
export const getSubdomain = () => {
  // herokuやCDNのドメインで見てるときは、サブドメインなしとみなす
  if (
    location.hostname.endsWith('.herokuapp.com') ||
    location.hostname.endsWith('.cloudfront.net')
  ) {
    return null
  }
  const tokens = location.hostname.split('.')
  if (tokens.length <= 2) {
    // サブドメインなし
    return null
  } else if (tokens[0] === 'www' || tokens[0] === 'origin') {
    // www or origin はサブドメインなしとみなす
    return null
  } else {
    return tokens[0]
  }
}

/**
 * link rel=canonical用の正規化URLを返す。
 * @returns {string}
 */
export const getCanonicalBaseUrl = () => {
  let hostname = location.hostname
  if (hostname.startsWith('origin.')) {
    hostname = hostname.replace('origin.', '')
  }
  if (hostname.includes('.origin.')) {
    hostname = hostname.replace('.origin.', '.')
  }
  return (
    location.protocol +
    '//' +
    hostname +
    (location.port ? ':' + location.port : '')
  )
}
