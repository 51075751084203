import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { Proxy } from '~/types/proxy'
import type { UserStatus } from '~/types/user'

export class KickflowProxyApplicantApi extends KickflowApi {
  async getProxyApplicants(
    paging: PagingQuery,
    userId: string | null | undefined,
    proxyUserId: string | null | undefined
  ): Promise<PagingList<Proxy>> {
    const queryParams = paging.toParams()
    if (userId) {
      queryParams.userId = userId
    }
    if (proxyUserId) {
      queryParams.proxyUserId = proxyUserId
    }
    return await this.getPagingList('/proxyApplicants', queryParams)
  }

  async createProxyApplicant(data: {
    userId: string
    proxyUserId: string
    workflowIds: string[]
    startsOn: string | null
    endsOn: string | null
  }): Promise<Proxy> {
    return await this.post('/proxyApplicants', data)
  }

  async deleteProxyApplicant(proxy: Proxy) {
    return await this.delete(`/proxyApplicants/${proxy.id}`)
  }

  async getMyProxyApplicants(paging: PagingQuery): Promise<PagingList<Proxy>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/user/proxyApplicants', queryParams)
  }

  async getMyProxyApplicantClients(
    paging: PagingQuery,
    active: boolean,
    status: UserStatus[] | null
  ): Promise<PagingList<Proxy>> {
    const queryParams = paging.toParams()
    queryParams.active = active
    if (status) {
      queryParams.status = status
    }
    return await this.getPagingList('/user/proxyApplicantClients', queryParams)
  }
}

export class KickflowProxyApproverApi extends KickflowApi {
  async getProxyApprovers(
    paging: PagingQuery,
    userId: string | null | undefined,
    proxyUserId: string | null | undefined
  ): Promise<PagingList<Proxy>> {
    const queryParams = paging.toParams()
    if (userId) {
      queryParams.userId = userId
    }
    if (proxyUserId) {
      queryParams.proxyUserId = proxyUserId
    }
    return await this.getPagingList('/proxyApprovers', queryParams)
  }

  async createProxyApprover(data: {
    userId: string
    proxyUserId: string
    workflowIds: string[]
    startsOn: string | null
    endsOn: string | null
  }): Promise<Proxy> {
    return await this.post('/proxyApprovers', data)
  }

  async deleteProxyApprover(proxy: Proxy) {
    return await this.delete(`/proxyApprovers/${proxy.id}`)
  }

  async getMyProxyApprovers(paging: PagingQuery): Promise<PagingList<Proxy>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/user/proxyApprovers', queryParams)
  }

  async getMyProxyApproverClients(
    paging: PagingQuery
  ): Promise<PagingList<Proxy>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/user/proxyApproverClients', queryParams)
  }
}

export class KickflowProxySettingApi extends KickflowApi {
  async updateProxyApplicant(enabled: boolean) {
    return await this.patch('/proxySetting', { allowApplicant: enabled })
  }

  async updateProxyApprover(enabled: boolean) {
    return await this.patch('/proxySetting', { allowApprover: enabled })
  }
}
