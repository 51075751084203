import { KickflowApi } from '~/api/kickflowApi'
import type { ClimberCloudSetting } from '~/types/ClimberCloud'

export class KickflowClimberCloudApi extends KickflowApi {
  async getClimberCloud(): Promise<ClimberCloudSetting> {
    return await this.get('/climberCloud')
  }

  async createClimberCloud(params: {
    climberCloudTenantId: string
    climberCloudAPIKey: string
  }): Promise<ClimberCloudSetting> {
    return await this.post('/climberCloud', params)
  }

  async updateClimberCloud(params: {
    climberCloudTenantId: string
    climberCloudAPIKey: string
  }): Promise<ClimberCloudSetting> {
    return await this.patch('/climberCloud', params)
  }
  async destroyClimberCloud(climberCloudTenantId: string) {
    return await this.delete('/climberCloud', { climberCloudTenantId })
  }
}
