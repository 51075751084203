import { KickflowApi } from '~/api/kickflowApi'
import type { Registration } from '~/types/registration'

export class KickflowRegistrationApi extends KickflowApi {
  async createRegistration(params: any): Promise<Registration> {
    return await this.post('/registrations', params)
  }

  async getRegistration(id: string): Promise<Registration> {
    return await this.get(`/registrations/${id}`)
  }
}
