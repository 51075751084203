import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { Backup } from '~/types/backup'
import type { TenantSettingBody } from '~/types/body/tenantSettingBody'
import type {
  NotificationSetting,
  NotificationSettingRow,
} from '~/types/notificationSetting'
import type { RemindNotificationSetting } from '~/types/remindNotificationSetting'
import type {
  FeatureAvailability,
  Tenant,
  TenantPublicInfo,
} from '~/types/tenant'
import type { TenantSetting } from '~/types/tenantSetting'
import type { TutorialStatus } from '~/types/tutorialStatus'

/**
 * テナントAPI
 */
export class KickflowTenantApi extends KickflowApi {
  async getCurrentTenant(): Promise<Tenant> {
    return await this.get('/tenant')
  }

  async getTenant(publicId: string): Promise<TenantPublicInfo> {
    return await this.get(`/tenants/${publicId}`)
  }

  async createTenant(params: any): Promise<Tenant> {
    return await this.post('/tenants', params)
  }

  async updateName(params: { name: string }): Promise<Tenant> {
    return await this.put('/tenant', params)
  }

  async updatePublicId(params: { publicId: string }): Promise<Tenant> {
    return await this.put('/tenant', params)
  }

  async upsertTenantNews(params: { body: string | null | undefined }) {
    return await this.post('/tenantNews', params)
  }

  async getFeatureAvailability(): Promise<FeatureAvailability> {
    return await this.get('/featureAvailability')
  }

  /*
   * チュートリアル
   */

  async getTutorialStatus(): Promise<TutorialStatus> {
    return await this.get('/tutorialStatus')
  }

  async updateTutorialStatus(params: {
    progress: number
    completed: boolean
  }): Promise<TutorialStatus> {
    return await this.patch('/tutorialStatus', params)
  }

  /*
   * 通知設定
   */

  async getNotificationDefaultSetting(): Promise<NotificationSetting> {
    return await this.get('/notificationDefaultSetting')
  }

  async updateNotificationDefaultSetting(
    notificationSetting: NotificationSettingRow,
    overwriteUserSettings: boolean
  ): Promise<NotificationSettingRow> {
    const params = { ...notificationSetting, overwriteUserSettings }
    return await this.patch(
      `/notificationDefaultSetting/${notificationSetting.notificationType}`,
      params
    )
  }

  async getRemindNotificationSetting(): Promise<RemindNotificationSetting> {
    return await this.get('/remindNotificationSettings')
  }

  async updateRemindNotificationSetting(
    params: RemindNotificationSetting
  ): Promise<RemindNotificationSetting> {
    return await this.patch('/remindNotificationSettings', params)
  }

  /*
   * 作業中モード
   */

  async createAdminWorkingStatus() {
    return await this.post('/adminWorkingStatus')
  }

  async destroyAdminWorkingStatus() {
    return await this.delete('/adminWorkingStatus')
  }

  /*
   * バックアップ
   */
  async getBackups(paging: PagingQuery): Promise<PagingList<Backup>> {
    const params = paging.toParams()
    return await this.getPagingList('/backups', params)
  }

  async createBackupAudit(filename: string, datetime: string) {
    const params = { filename, datetime }
    return await this.post('/backups/audit', params)
  }

  /*
   * その他の設定
   */
  async getTenantSetting(): Promise<TenantSetting> {
    return await this.get('/tenantSetting')
  }

  async updateTenantSetting(params: TenantSettingBody): Promise<TenantSetting> {
    return await this.put('/tenantSetting', params)
  }
}
