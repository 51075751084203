import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { CalculationVariableBody } from '~/types/body/calculationVariableBody'
import type { CalculationVariable } from '~/types/calculationVariable'

/**
 * パイプラインAPI
 */
export class KickflowCalculationVariableApi extends KickflowApi {
  async getVariables(
    paging: PagingQuery
  ): Promise<PagingList<CalculationVariable>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/calculationVariables', queryParams)
  }

  async createVariable(
    params: CalculationVariableBody
  ): Promise<CalculationVariable> {
    return await this.post('/calculationVariables', params)
  }

  async updateVariable(
    variable: CalculationVariable,
    params: CalculationVariableBody
  ): Promise<CalculationVariable> {
    return await this.put(`/calculationVariables/${variable.id}`, params)
  }

  async deleteVariable(
    variable: CalculationVariable
  ): Promise<CalculationVariable> {
    return await this.delete(`/calculationVariables/${variable.id}`)
  }
}
