import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { SubStatus } from '~/types/subStatus'
import type { TicketNumberKey } from '~/types/ticketNumberKey'
import type { Workflow } from '~/types/workflow'

export class KickflowTicketNumberKeyApi extends KickflowApi {
  async getTicketNumberKeys(
    paging: PagingQuery
  ): Promise<PagingList<TicketNumberKey>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/ticketNumberKeys', queryParams)
  }

  async createTicketNumberKey(params: {
    name: string
    code: string
    notes: string
  }): Promise<TicketNumberKey> {
    return await this.post('/ticketNumberKeys', params)
  }

  async getTicketNumberKey(id: string): Promise<TicketNumberKey> {
    return await this.get(`/ticketNumberKeys/${id}`)
  }

  async updateTicketNumberKey(
    ticketNumberKey: TicketNumberKey,
    params: {
      name: string
      code: string
      notes: string
    }
  ): Promise<SubStatus> {
    return await this.patch(`/ticketNumberKeys/${ticketNumberKey.id}`, params)
  }

  async deleteTicketNumberKey(ticketNumberKey: TicketNumberKey) {
    return await this.delete(`/ticketNumberKeys/${ticketNumberKey.id}`)
  }

  async getReferringWorkflows(
    ticketNumberKey: TicketNumberKey,
    paging: PagingQuery
  ): Promise<PagingList<Workflow>> {
    const queryParams = paging.toParams()
    return await this.getPagingList(
      `/ticketNumberKeys/${ticketNumberKey.id}/workflows`,
      queryParams
    )
  }

  async updateDefaultTicketNumberKey(ticketNumberKey: TicketNumberKey | null) {
    const params = {
      ticketNumberKeyId: ticketNumberKey ? ticketNumberKey.id : null,
    }
    return await this.put(`/defaultTicketNumberKey`, params)
  }

  async getDefaultTicketNumberKey(): Promise<TicketNumberKey | null> {
    const resp = (await this.get(`/defaultTicketNumberKey`)) as {
      ticketNumberKey: TicketNumberKey | null
    }
    return resp.ticketNumberKey
  }
}
