import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { BillingAddress } from '~/types/billingAddress'
import type { Invoice } from '~/types/invoice'
import type { LicenseOrder } from '~/types/licenseOrder'
import type { Subscription } from '~/types/subscription'

/**
 * 決済API
 */
export class KickflowBillingApi extends KickflowApi {
  async getInvoices(paging: PagingQuery): Promise<PagingList<Invoice>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/invoices', queryParams)
  }

  async getInvoicePdf(invoice: Invoice, filename: string) {
    const blob = await this.getBlob(`/invoices/${invoice.id}/pdf`)
    downloadPDF(blob, filename)
  }

  async getLicenseOrders(
    paging: PagingQuery
  ): Promise<PagingList<LicenseOrder>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/licenseOrders', queryParams)
  }

  async purchaseLicense(quantity: number) {
    const params = {
      quantity,
    }
    return await this.post('/licenseOrders', params)
  }

  async getBillingAddress(): Promise<BillingAddress> {
    return await this.get('/billingAddress')
  }

  async updateBillingAddress(params: {
    name: string
    zipCode: string
    prefecture: string
    city: string
    address: string
    building: string | null
    personInChargeName: string
    personInChargeEmail: string
    personInChargePhoneNumber: string
  }): Promise<BillingAddress> {
    return await this.patch('/billingAddress', params)
  }

  async getSubscription(): Promise<Subscription> {
    return await this.get('/subscription')
  }
}
