import type { TranslateResult } from 'vue-i18n'

import { useCommonUiStore } from '~/stores/commonUi'

export default defineNuxtPlugin((_nuxtApp) => {
  const showSnackbar = (message: TranslateResult) => {
    const store = useCommonUiStore()
    store.showSnackbar(message as string)
  }

  const showErrorSnackbar = (e: any) => {
    const store = useCommonUiStore()
    store.showErrorSnackbar(e)
  }

  return {
    provide: {
      showSnackbar,
      showErrorSnackbar,
    },
  }
})
