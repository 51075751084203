<template>
  <VitePwaManifest />
  <NuxtLayout>
    <NuxtLoadingIndicator color="#00BCD4" />
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
// ログイン直後にLoading indicatorが消えないバグのwork around
// https://github.com/nuxt/nuxt/issues/25840
const { finish: finishNuxtLoading } = useLoadingIndicator()
finishNuxtLoading()
if (import.meta.client) {
  finishNuxtLoading()
}
</script>
