import { KickflowApi } from '~/api/kickflowApi'
import type { ChatworkApiToken } from '~/types/tenant'

export class KickflowChatworkApi extends KickflowApi {
  async getChatworkApiToken(): Promise<ChatworkApiToken> {
    return await this.get('/chatwork')
  }

  async connect(params: { token: string }) {
    return await this.post('/chatwork', params)
  }

  async disconnect() {
    return await this.delete('/chatwork')
  }

  async ping(roomId: string) {
    return await this.post('/chatwork/ping', { roomId })
  }
}
