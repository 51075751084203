<template>
  <KApp>
    <KMain>
      <KContainer class="fill-height">
        <KRow align="center" justify="center" no-gutters>
          <KCol :cols="mobile ? 12 : 6">
            <KImg
              v-if="error.statusCode === 503"
              :alt="error.message"
              max-width="600"
              src="/images/503.svg"
            />
            <KImg
              v-else-if="error.statusCode >= 500"
              :alt="error.message"
              max-width="600"
              src="/images/500.svg"
            />
            <KImg
              v-else-if="error.statusCode === 401"
              :alt="error.message"
              max-width="600"
              src="/images/401.svg"
            />
            <KImg
              v-else-if="error.statusCode === 403"
              :alt="error.message"
              max-width="600"
              src="/images/403.svg"
            />
            <KImg
              v-else-if="error.statusCode === 404"
              :alt="error.message"
              max-width="600"
              src="/images/404.svg"
            />
            <KImg
              v-else
              :alt="error.message"
              max-width="600"
              src="/images/400.svg"
            />

            <div class="d-flex justify-center">
              <KBtn color="primary" variant="text" @click="handleError">{{
                $t('エラーページ.サインインに戻る')
              }}</KBtn>
            </div>
          </KCol>
        </KRow>
      </KContainer>
    </KMain>
  </KApp>
</template>

<script setup lang="ts">
// ref: https://nuxt.com/docs/getting-started/error-handling#rendering-an-error-page
import { useDisplay } from 'vuetify'

defineProps<{
  error: {
    message: string
    statusCode: number
  }
}>()

const { mobile } = useDisplay()

const handleError = () => clearError({ redirect: '/signin' })
</script>
