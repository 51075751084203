import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { Assistant } from '~/types/assistant'

/**
 * Assistant API
 */
export class KickflowAssistantApi extends KickflowApi {
  async getAssistants(paging: PagingQuery): Promise<PagingList<Assistant>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/assistants', queryParams)
  }

  async createAssistant(params: {
    email: string
    expiresAt: string
  }): Promise<Assistant> {
    return await this.post('/assistants', params)
  }

  async deleteAssistant(assistant: Assistant) {
    return await this.delete(`/assistants/${assistant.id}`)
  }

  async checkAssistant(id: string) {
    return await this.get(`/assistants/${id}/check`)
  }
}
