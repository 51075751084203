import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type {
  FolderCreateBody,
  FolderUpdateBody,
} from '~/types/body/folderBody'
import type { DetailedFolder, Folder } from '~/types/folder'

/**
 * フォルダAPI
 */
export class KickflowFolderApi extends KickflowApi {
  async getFolders(
    paging: PagingQuery,
    q?: string | null
  ): Promise<PagingList<Folder>> {
    const queryParams = paging.toParams()
    if (q) {
      queryParams.q = q
    }
    return await this.getPagingList('/folders', queryParams)
  }

  /**
   * 管理可能なフォルダ一覧を取得
   * @param paging
   * @param parentId nullの場合、全フォルダが対象。
   * @param q
   */
  async getManageableFolders(
    paging: PagingQuery,
    parentId: string | null,
    q?: string | null
  ): Promise<PagingList<Folder>> {
    const queryParams = paging.toParams()
    if (parentId) {
      queryParams.parentId = parentId
    }
    if (q) {
      queryParams.q = q
    }
    return await this.getPagingList('/folders/manageable', queryParams)
  }

  async createFolder(params: FolderCreateBody): Promise<Folder> {
    return await this.post('/folders', params)
  }

  async getFolder(id: string): Promise<DetailedFolder> {
    return await this.get(`/folders/${id}`)
  }

  async getRootFolder(): Promise<DetailedFolder> {
    return await this.get('/folders/root')
  }

  async updateFolder(
    folder: Folder,
    params: FolderUpdateBody
  ): Promise<Folder> {
    return await this.put(`/folders/${folder.id}`, params)
  }

  async bulkUpdateFolder(
    folderIds: string[],
    parentFolderId: string
  ): Promise<Folder> {
    const params = {
      folderId: folderIds,
      parentFolderId,
    }
    return await this.patch('/folders/bulkUpdate', params)
  }

  async deleteFolder(folder: Folder) {
    return await this.delete(`/folders/${folder.id}`)
  }
}
