import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { ServiceAccountBody } from '~/types/body/serviceAccountBody'
import type { ServiceAccount } from '~/types/serviceAccount'
import type { WebhookSetting } from '~/types/webhook'

/**
 * ServiceAccount API
 */
export class KickflowServiceAccountApi extends KickflowApi {
  async getServiceAccounts(
    pagingQuery: PagingQuery
  ): Promise<PagingList<ServiceAccount>> {
    const params = pagingQuery.toParams()
    return await this.getPagingList('/serviceAccounts', params)
  }

  async getServiceAccount(id: string): Promise<ServiceAccount[]> {
    return await this.get(`/serviceAccounts/${id}`)
  }

  async createServiceAccount(
    params: ServiceAccountBody
  ): Promise<ServiceAccount> {
    return await this.post('/serviceAccounts', params)
  }

  async updateServiceAccount(
    account: ServiceAccount,
    params: ServiceAccountBody
  ): Promise<WebhookSetting> {
    return await this.put(`/serviceAccounts/${account.id}`, params)
  }

  async deleteServiceAccount(account: ServiceAccount) {
    return await this.delete(`/serviceAccounts/${account.id}`)
  }
}
