import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { Grade } from '~/types/grade'

/**
 * 役職API
 */
export class KickflowGradeApi extends KickflowApi {
  async getGrades(paging: PagingQuery): Promise<PagingList<Grade>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/grades', queryParams)
  }

  async createGrade(params: {
    name: string
    level: number
    code?: string | null
  }): Promise<Grade> {
    return await this.post('/grades', params)
  }

  async updateGrade(
    grade: Grade,
    params: { name: string; level: number; code?: string }
  ): Promise<Grade> {
    return await this.patch(`/grades/${grade.id}`, params)
  }

  async getDefaultGrade(): Promise<Grade> {
    return await this.get('/grades/default')
  }

  async changeDefaultGrade(grade: Grade) {
    return await this.post(`/grades/${grade.id}/default`)
  }

  async deleteGrade(grade: Grade) {
    return await this.delete(`/grades/${grade.id}`)
  }

  async lookupByCode(code: string[]) {
    const params = { code }
    return (await this.get('/grades/lookupByCode', params)) as Grade[]
  }
}
