import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { AccessToken } from '~/types/token'

/**
 * PAT API
 */
export class KickflowPersonalAccessTokenApi extends KickflowApi {
  async getTokens(paging: PagingQuery): Promise<PagingList<AccessToken>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/user/accessTokens', queryParams)
  }

  async createToken(name: string): Promise<AccessToken> {
    const params = {
      name,
    }
    return await this.post('/user/accessTokens', params)
  }

  async updateToken(token: AccessToken, name: string): Promise<AccessToken> {
    const params = {
      name,
    }
    return await this.put(`/user/accessTokens/${token.id}`, params)
  }

  async regenerateToken(token: AccessToken): Promise<AccessToken> {
    return await this.put(`/user/accessTokens/${token.id}/regenerate`)
  }

  async deleteToken(token: AccessToken) {
    return await this.delete(`/user/accessTokens/${token.id}`)
  }
}
