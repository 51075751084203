import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { AuditLog } from '~/types/auditLog'
import type { Encoding } from '~/types/encoding'
import type { Progress } from '~/types/progress'
import type { User } from '~/types/user'

/**
 * Audit Log API
 */
export class KickflowAuditLogApi extends KickflowApi {
  async getAuditLogs(
    paging: PagingQuery,
    user: User | null,
    category: string | null,
    createdAtStart: string | null,
    createdAtEnd: string | null
  ): Promise<PagingList<AuditLog>> {
    const queryParams = paging.toParams()
    if (user) {
      queryParams.userId = user.id
    }
    if (category) {
      queryParams.category = category
    }
    if (createdAtStart) {
      queryParams.createdAtStart = createdAtStart
    }
    if (createdAtEnd) {
      queryParams.createdAtEnd = createdAtEnd
    }
    return await this.getPagingList('/auditLogs', queryParams)
  }

  async exportCsv(encoding: Encoding): Promise<Progress> {
    const params = {
      encoding,
    }
    return await this.get('/auditLogs/csv', params)
  }
}
