import { KickflowApi } from '~/api/kickflowApi'
import type { SlackAccessToken, SlackChannel } from '~/types/slack'

export class KickflowSlackApi extends KickflowApi {
  async getSlackAccessTokens(): Promise<SlackAccessToken[]> {
    return await this.get('/slack')
  }

  async connect(params: { code: string }) {
    return await this.post('/slack', params)
  }

  async disconnect(id: string) {
    return await this.delete(`/slack/${id}`)
  }

  async getChannel(tokenId: string, channelId: string): Promise<SlackChannel> {
    return await this.get(`/slack/${tokenId}/channels/${channelId}`)
  }
}
