import { ofetch } from 'ofetch'

export default defineNuxtPlugin(async (_nuxtApp) => {
  const ofetchInstance = ofetch.create({
    onRequestError: (_ctx) => {
      showError({})
    },
    onResponseError: (context) => {
      try {
        const status = context.response.status
        if (status >= 500) {
          showError({
            statusCode: status,
          })
        } else if (status === 401) {
          navigateTo('/signin')
        }
      } catch {
        // 多重にリダイレクトしようとしたときに、エラーが発生するので握りつぶす
        // https://v3.router.vuejs.org/ja/guide/advanced/navigation-failures.html
      }
    },
  })

  const httpClient = new HttpClient(ofetchInstance)

  return {
    provide: {
      httpClient,
    },
  }
})
