import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { RuleBody, RuleStatusBody } from '~/types/body/ruleBody'
import type { Rule } from '~/types/rule'

/**
 * オートメーションAPI
 */
export class KickflowAutomationApi extends KickflowApi {
  async getRules(paging: PagingQuery): Promise<PagingList<Rule>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/rules', queryParams)
  }

  async getRule(id: string): Promise<Rule> {
    return await this.get(`/rules/${id}`)
  }

  async createRule(params: RuleBody): Promise<Rule> {
    return await this.post('/rules', params)
  }

  async updateRule(rule: Rule, params: RuleBody | RuleStatusBody) {
    return await this.patch(`/rules/${rule.id}`, params)
  }

  async deleteRule(rule: Rule) {
    return await this.delete(`/rules/${rule.id}`)
  }
}
