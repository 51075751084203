import { createAuth0 } from '@auth0/auth0-vue'

// auth0-vueをNuxt3のプラグインとして登録する
// https://stackoverflow.com/a/74694136
export default defineNuxtPlugin((nuxtApp) => {
  console.log('[auth0 plugin] start')

  const config = useRuntimeConfig()
  const auth0 = createAuth0({
    domain: config.public.auth0Domain,
    clientId: config.public.auth0ClientId,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    authorizationParams: {
      audience: config.public.auth0ApiAud,
      redirect_uri: window.location.origin + '/auth/callback',
      locale: 'ja-JP ja en',
    },
  })

  nuxtApp.vueApp.use(auth0)
  console.log('[auth0 plugin] set auth0 to vue app')

  console.log('[auth0 plugin] end')

  return {
    provide: {
      auth0,
    },
  }
})
