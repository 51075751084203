import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type {
  RoleBody,
  RoleNameBody,
  RolePermissionListBody,
} from '~/types/body/roleBody'
import type { Role } from '~/types/role'
import type { User } from '~/types/user'

/**
 * 管理者ロールAPI
 */
export class KickflowRoleApi extends KickflowApi {
  async getRoles(paging: PagingQuery): Promise<PagingList<Role>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/roles', queryParams)
  }

  async getRole(id: string): Promise<Role> {
    return await this.get(`/roles/${id}`)
  }

  async createRole(params: RoleBody): Promise<Role> {
    return await this.post('/roles', params)
  }

  async updateRoleName(role: Role, params: RoleNameBody) {
    return await this.put(`/roles/${role.id}`, params)
  }

  async updateRolePermissions(role: Role, params: RolePermissionListBody) {
    return await this.put(`/roles/${role.id}`, params)
  }

  async deleteRole(role: Role) {
    return await this.delete(`/roles/${role.id}`)
  }

  async getRoleMembers(
    role: Role,
    paging: PagingQuery
  ): Promise<PagingList<User>> {
    const queryParams = paging.toParams()
    return await this.getPagingList(`/roles/${role.id}/members`, queryParams)
  }

  async createRoleMember(role: Role, users: User[]) {
    const data = {
      userId: users.map((user) => user.id),
    }
    return await this.post(`/roles/${role.id}/members`, data)
  }

  async removeRoleMember(role: Role, user: User) {
    return await this.delete(`/roles/${role.id}/members/${user.id}`)
  }
}
