import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { Comment } from '~/types/comment'
import type { Ticket } from '~/types/ticket'

/**
 * コメントAPI
 */
export class KickflowCommentApi extends KickflowApi {
  async getComments(ticket: Ticket): Promise<Comment[]> {
    return await this.get(`/tickets/${ticket.id}/comments?perPage=100`)
  }

  async getCommentsByTicketId(
    paging: PagingQuery,
    id: string
  ): Promise<PagingList<Comment>> {
    const params = paging.toParams()
    return await this.getPagingList(`/tickets/${id}/comments`, params)
  }

  async postComment(ticket: Ticket, params: { body: string; files: string[] }) {
    await this.post(`/tickets/${ticket.id}/comments`, params)
  }

  async updateComment(
    ticket: Ticket,
    comment: Comment,
    params: { body: string }
  ) {
    await this.patch(`/tickets/${ticket.id}/comments/${comment.id}`, params)
  }

  async deleteComment(ticket: Ticket, comment: Comment) {
    await this.delete(`/tickets/${ticket.id}/comments/${comment.id}`)
  }
}
