import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { MonthlyActivitySummary } from '~/types/monthlyActivity'
import type { Progress } from '~/types/progress'

/**
 * アクティブユーザー集計API
 */
export class KickflowMonthlyActivityApi extends KickflowApi {
  async getSummaries(
    paging: PagingQuery
  ): Promise<PagingList<MonthlyActivitySummary>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/monthlyActivitySummaries', queryParams)
  }

  async exportCsv(summary: MonthlyActivitySummary): Promise<Progress> {
    return await this.get(`/monthlyActivitySummaries/${summary.id}/csv`)
  }
}
