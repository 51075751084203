import { Buffer } from 'buffer'
import type { $Fetch, FetchResponse } from 'ofetch'
import { ofetch } from 'ofetch'

/**
 * 汎用的なHTTPクライアントクラス
 * kickflow API以外のエンドポイントにも使うので、kickflow API固有ロジックは含まない。
 */
export class HttpClient {
  private readonly ofetchInstance: $Fetch

  private baseUrl: string | undefined

  private readonly headers: { [key: string]: string | undefined }

  constructor(ofetchInstance: $Fetch) {
    this.ofetchInstance = ofetchInstance
    this.headers = {
      'Content-Type': 'application/json',
    }
  }

  setBaseUrl(url: string) {
    this.baseUrl = url
  }

  /**
   * ベーシック認証のID/PWを設定
   * @param user
   * @param pass
   */
  setBasicAuth(user: string | null, pass: string | null) {
    const token = Buffer.from(user + ':' + pass).toString('base64')
    this.headers.Authorization = `Basic ${token}`
  }

  /**
   * ヘッダの設定
   * @param key
   * @param value
   */
  setHeader(key: string, value: string | undefined) {
    this.headers[key] = value
  }

  async get<T = any>(
    url: string,
    queryParams?: Record<string, any>,
    headers?: { [key: string]: string | undefined }
  ): Promise<T> {
    return await this.ofetchInstance(url, {
      baseURL: this.baseUrl,
      method: 'GET',
      headers: this.convertHeaders(this.mergeHeaders(this.headers, headers)),
      params: this.addBracketsForArrayParameters(queryParams),
    })
  }

  async getRaw(
    url: string,
    queryParams?: Record<string, any>,
    headers?: { [key: string]: string | undefined }
  ): Promise<FetchResponse<any>> {
    return await this.ofetchInstance.raw(url, {
      baseURL: this.baseUrl,
      method: 'GET',
      headers: this.convertHeaders(this.mergeHeaders(this.headers, headers)),
      params: this.addBracketsForArrayParameters(queryParams),
    })
  }

  async getBlob(
    url: string,
    queryParams?: Record<string, any>,
    headers?: { [key: string]: string | undefined }
  ): Promise<Blob> {
    return await this.ofetchInstance(url, {
      baseURL: this.baseUrl,
      method: 'GET',
      headers: this.convertHeaders(this.mergeHeaders(this.headers, headers)),
      params: this.addBracketsForArrayParameters(queryParams),
      responseType: 'blob',
    })
  }

  async delete<T = any>(
    url: string,
    queryParams?: Record<string, any>,
    headers?: { [key: string]: string | undefined }
  ): Promise<T> {
    return await this.ofetchInstance(url, {
      baseURL: this.baseUrl,
      method: 'DELETE',
      headers: this.convertHeaders(this.mergeHeaders(this.headers, headers)),
      params: this.addBracketsForArrayParameters(queryParams),
    })
  }

  async post<T = any>(
    url: string,
    params?: any,
    headers?: { [key: string]: string | undefined }
  ): Promise<T> {
    return await this.ofetchInstance(url, {
      baseURL: this.baseUrl,
      method: 'POST',
      headers: this.convertHeaders(this.mergeHeaders(this.headers, headers)),
      body: params,
    })
  }

  async put<T = any>(
    url: string,
    params?: any,
    headers?: { [key: string]: string | undefined }
  ): Promise<T> {
    return await this.ofetchInstance(url, {
      baseURL: this.baseUrl,
      method: 'PUT',
      headers: this.convertHeaders(this.mergeHeaders(this.headers, headers)),
      body: params,
    })
  }

  async patch<T = any>(
    url: string,
    params?: any,
    headers?: { [key: string]: string | undefined }
  ): Promise<T> {
    return await this.ofetchInstance(url, {
      baseURL: this.baseUrl,
      method: 'PATCH',
      headers: this.convertHeaders(this.mergeHeaders(this.headers, headers)),
      body: params,
    })
  }

  /**
   * ヘッダをofetchで利用可能な型に変換する
   * @param map
   * @private
   */
  private convertHeaders(map: {
    [key: string]: string | undefined
  }): [string, string][] {
    const headers: [string, string][] = []
    for (const key in map) {
      const value = map[key]
      if (value) {
        headers.push([key, value])
      }
    }
    return headers
  }

  private mergeHeaders(
    h1: { [key: string]: string | undefined },
    h2: { [key: string]: string | undefined } | undefined
  ): { [key: string]: string | undefined } {
    if (h2) {
      return Object.assign(h1, h2)
    } else {
      return h1
    }
  }

  private addBracketsForArrayParameters(
    params?: Record<string, any>
  ): Record<string, any> | undefined {
    if (!params) {
      return undefined
    }
    const transformed: Record<string, any> = {}
    for (const [k, v] of Object.entries(params)) {
      transformed[Array.isArray(v) ? `${k}[]` : k] = v
    }
    return transformed
  }
}

/**
 * HttpClientのインスタンスを生成する
 */
export function createHttpClient(): HttpClient {
  return new HttpClient(ofetch.create({}))
}
