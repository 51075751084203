import type { PagingList } from '~/api/kickflowApi'
import { KickflowApi, PagingQuery } from '~/api/kickflowApi'
import type { PipelineBody } from '~/types/body/pipelineBody'
import type { Pipeline } from '~/types/pipeline'
import type { DetailedTicket } from '~/types/ticket'

/**
 * パイプラインAPI
 */
export class KickflowPipelineApi extends KickflowApi {
  async getPipelines(
    paging: PagingQuery,
    name?: string | null,
    folderId?: string[]
  ): Promise<PagingList<Pipeline>> {
    const queryParams = paging.toParams()
    if (name) {
      queryParams.name = name
    }
    if (folderId) {
      queryParams.folderId = folderId
    }
    return await this.getPagingList('/pipelines', queryParams)
  }

  async getPipeline(id: string): Promise<Pipeline> {
    return await this.get(`/pipelines/${id}`)
  }

  async createPipeline(params: PipelineBody): Promise<Pipeline> {
    return await this.post('/pipelines', params)
  }

  async updatePipeline(
    pipeline: Pipeline,
    params: PipelineBody
  ): Promise<Pipeline> {
    return await this.put(`/pipelines/${pipeline.id}`, params)
  }

  async deletePipeline(pipeline: Pipeline): Promise<Pipeline> {
    return await this.delete(`/pipelines/${pipeline.id}`)
  }

  async countManualPipelines(workflowId: string): Promise<number> {
    const response = await this.search(
      new PagingQuery({ perPage: 1 }),
      '',
      ['manual'],
      workflowId
    )
    return response.total
  }

  async search(
    paging: PagingQuery,
    q: string,
    pipelineTypes: string[],
    workflowId: string
  ): Promise<PagingList<Pipeline>> {
    const params = paging.toParams()
    params.q = q
    params.pipelineTypes = pipelineTypes
    params.workflowId = workflowId
    return await this.getPagingList('/pipelines/search', params)
  }

  async execute(pipeline: Pipeline, ticketId: string): Promise<DetailedTicket> {
    return await this.post(
      `/pipelines/${pipeline.id}/execute?ticketId=${ticketId}`
    )
  }
}
