import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type {
  Notification,
  NotificationType,
  UnreadCountData,
} from '~/types/notification'

export interface KickflowNotificationApiGetNotificationsParams {
  notificationType?: NotificationType[]
}

/**
 * 通知API
 */
export class KickflowNotificationApi extends KickflowApi {
  async getNotifications(
    paging: PagingQuery,
    params: KickflowNotificationApiGetNotificationsParams
  ): Promise<PagingList<Notification>> {
    const queryParams = paging.toParams()
    Object.assign(params, queryParams)
    return await this.getPagingList('/notifications', params)
  }

  async getUnreadCount(): Promise<UnreadCountData> {
    return await this.get('/notifications/unreadCount')
  }

  async read() {
    return await this.post('/notifications/read')
  }
}
