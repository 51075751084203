import { KickflowApi } from '~/api/kickflowApi'
import type { BoxAccessToken } from '~/types/box'

export class KickflowBoxApi extends KickflowApi {
  async getBoxAccessToken(): Promise<BoxAccessToken> {
    return await this.get('/box')
  }

  async connect(params: { code: string }) {
    return await this.post('/box', params)
  }

  async disconnect() {
    return await this.delete(`/box`)
  }
}
