import { KickflowApi } from '~/api/kickflowApi'
import type { LineWorksApiSetting } from '~/types/lineWorks'

export class KickflowLineWorksApi extends KickflowApi {
  async getLineWorks(): Promise<LineWorksApiSetting> {
    return await this.get('/lineWorks')
  }

  async connect(params: {
    clientId: string
    clientSecret: string
    serviceAccountId: string
    privateKey: string
    botId: string
    botSecret: string
  }) {
    return await this.patch('/lineWorks', params)
  }

  async disconnect() {
    return await this.delete('/lineWorks')
  }
}
