import { KickflowApi } from '~/api/kickflowApi'

export interface FileRedirect {
  url: string
}

/**
 * File API
 */
export class KickflowFileApi extends KickflowApi {
  async getFile(signedId: string): Promise<FileRedirect> {
    return await this.get(`/activeStorage/blobs/${signedId}`)
  }

  async updateBlob(signedId: string): Promise<Blob> {
    const params = {
      signedId,
    }
    return await this.patch(`/files/blob`, params)
  }
}
