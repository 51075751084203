import { KickflowApi } from '~/api/kickflowApi'
import type { Progress } from '~/types/progress'

/**
 * Progress API
 */
export class KickflowProgressApi extends KickflowApi {
  async getProgress(id: string): Promise<Progress> {
    return await this.get(`/progresses/${id}`)
  }
}
