import type { PagingList, PagingQuery } from '~/api/kickflowApi'
import { KickflowApi } from '~/api/kickflowApi'
import type { SubStatus } from '~/types/subStatus'

export class KickflowSubStatusApi extends KickflowApi {
  async getSubStatuses(paging: PagingQuery): Promise<PagingList<SubStatus>> {
    const queryParams = paging.toParams()
    return await this.getPagingList('/subStatuses', queryParams)
  }

  async createSubStatus(params: {
    name: string
    code: string
    notes: string
  }): Promise<SubStatus> {
    return await this.post('/subStatuses', params)
  }

  async updateSubStatus(
    subStatus: SubStatus,
    params: {
      name: string
      code: string
      notes: string
    }
  ): Promise<SubStatus> {
    return await this.patch(`/subStatuses/${subStatus.id}`, params)
  }

  async deleteSubStatus(subStatus: SubStatus) {
    return await this.delete(`/subStatuses/${subStatus.id}`)
  }
}
