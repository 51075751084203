import { KickflowApi } from '~/api/kickflowApi'
import type { GoogleDriveAccessToken } from '~/types/googleDrive'

export class KickflowGoogleDriveApi extends KickflowApi {
  async getGoogleDrive(): Promise<GoogleDriveAccessToken> {
    return await this.get('/googleDrive')
  }

  async createGoogleDrive(params: {
    auth0UserId: string
  }): Promise<GoogleDriveAccessToken> {
    return await this.post('/googleDrive', params)
  }

  async destroyGoogleDrive() {
    return await this.delete('/googleDrive')
  }
}
