import { default as indexB1Ls7uui7CMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/audit/index.vue?macro=true";
import { default as indexkTjbOOQmWYMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/authentication/index.vue?macro=true";
import { default as edit1fKU0tNGuVMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/automations/[id]/edit.vue?macro=true";
import { default as indexVL51BGUi9kMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/automations/index.vue?macro=true";
import { default as newg549uRqowhMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/automations/new.vue?macro=true";
import { default as indexnhW8jHRnfHMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/billing/index.vue?macro=true";
import { default as indexwJaJYSMzJCMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/calculationVariables/index.vue?macro=true";
import { default as indexkp92OSd7mSMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/categories/index.vue?macro=true";
import { default as callbackT418WrUPvYMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/box/callback.vue?macro=true";
import { default as index3nm8nTxeUfMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/box/index.vue?macro=true";
import { default as redirectZBGughc8toMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/box/redirect.vue?macro=true";
import { default as index3tmt25ijZAMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/chatwork/index.vue?macro=true";
import { default as indexiSkRGT7eM9Meta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue?macro=true";
import { default as indexJSTp5eeNVEMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue?macro=true";
import { default as indexlaoaajRM5YMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue?macro=true";
import { default as resultsiebPSh406bMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue?macro=true";
import { default as index9XWIwtdUkZMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue?macro=true";
import { default as callbackteJGZy7fHEMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue?macro=true";
import { default as indexACBu6cApJ7Meta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue?macro=true";
import { default as index4Vjo89f8mtMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue?macro=true";
import { default as callback4jG4cld0UcMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/slack/callback.vue?macro=true";
import { default as indexj6vdpo8msuMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/slack/index.vue?macro=true";
import { default as redirectHa4XNqqJzyMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/slack/redirect.vue?macro=true";
import { default as _91id_93lGPNLrvTLbMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/webhook/[id].vue?macro=true";
import { default as indexk163EfVUIrMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/webhook/index.vue?macro=true";
import { default as _91id_93YHEG6ZCFviMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/folders/[id].vue?macro=true";
import { default as index3N1LdRfcqYMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/folders/index.vue?macro=true";
import { default as indexkGZnTE6GlSMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/grades/index.vue?macro=true";
import { default as indexY3yUDuA8XMMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/index.vue?macro=true";
import { default as indexEY6Tl4vQ66Meta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/invitationDomains/index.vue?macro=true";
import { default as index3xXuO6J53fMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/invitationSetting/index.vue?macro=true";
import { default as indexDaQl5fSANvMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/ip/index.vue?macro=true";
import { default as indexEkPwHaFBw3Meta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/labels/index.vue?macro=true";
import { default as indexXrynbCrqGRMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/masters/[id]/index.vue?macro=true";
import { default as workflowsWpiZ1ldIlMMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue?macro=true";
import { default as indexFInJ2VeuyTMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/masters/index.vue?macro=true";
import { default as indexo7AmhY6k19Meta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue?macro=true";
import { default as csv1GpLkoQyd0Meta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue?macro=true";
import { default as index49RfxURyCzMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/[org]/index.vue?macro=true";
import { default as _91id_93AVzKQudp3OMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue?macro=true";
import { default as historycBAFLRdYlqMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/history.vue?macro=true";
import { default as indexCDv0ajEoJqMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/index.vue?macro=true";
import { default as _91id_938uyj4oHXGTMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/pipelines/[id].vue?macro=true";
import { default as index0Q2Lkz6qDDMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/pipelines/index.vue?macro=true";
import { default as newr59VjZ63e6Meta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/pipelines/new.vue?macro=true";
import { default as indextqX8PNvjXRMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/proxyApplicants/index.vue?macro=true";
import { default as indexLOQHwM4NWZMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/proxyApprovers/index.vue?macro=true";
import { default as _91id_93MnctPi8vYxMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/roles/[id].vue?macro=true";
import { default as indexZy1VUYjTBrMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/roles/index.vue?macro=true";
import { default as editKBUOfEnhYbMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/[id]/edit.vue?macro=true";
import { default as indexzOhMsjgjoEMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/[id]/index.vue?macro=true";
import { default as index2GylVtcsg5Meta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue?macro=true";
import { default as _91versionNumber_93XdUE1h7oC8Meta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue?macro=true";
import { default as index6cj6GUVBVVMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue?macro=true";
import { default as indexIVkuy9G4lNMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/index.vue?macro=true";
import { default as newfysGQfVOSPMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/new.vue?macro=true";
import { default as indexb3DBQO2jSUMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/stats/index.vue?macro=true";
import { default as index6OSAHVILbnMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/subStatuses/index.vue?macro=true";
import { default as assistants8dFx9MrfQeMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/assistants.vue?macro=true";
import { default as backupsUi4KWaIJ4UMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/backups.vue?macro=true";
import { default as indexbbV8y57rZOMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/index.vue?macro=true";
import { default as newsPaE4Qu0TeOMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/news.vue?macro=true";
import { default as notificationsP9sLEYzmMlMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/notifications.vue?macro=true";
import { default as remindSr09BYuFy3Meta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/remind.vue?macro=true";
import { default as settingsSU2ZQV7TmiMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/settings.vue?macro=true";
import { default as subdomainTz4ytuHfSmMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/subdomain.vue?macro=true";
import { default as workingIJKvnCYKQPMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/working.vue?macro=true";
import { default as workflows9hRyktLkNNMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/ticketNumberKeys/[id]/workflows.vue?macro=true";
import { default as indexXGSAmtrhapMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/ticketNumberKeys/index.vue?macro=true";
import { default as clean12bLxlUwexMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tickets/clean.vue?macro=true";
import { default as myticketb2OZjdGIm7Meta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue?macro=true";
import { default as taska7Ggvb9DwWMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tickets/transfer/task.vue?macro=true";
import { default as viewerD8I2qVG1qOMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tickets/viewer.vue?macro=true";
import { default as _91id_93rMyjffZ83DMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/users/[id].vue?macro=true";
import { default as csv7D3IwlQGmYMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/users/csv.vue?macro=true";
import { default as index4CqJ5U59FkMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/users/index.vue?macro=true";
import { default as inviteEmnvjGzvUWMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/users/invite.vue?macro=true";
import { default as unaffiliatedfKbULkhqPTMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/users/unaffiliated.vue?macro=true";
import { default as editB5i6l5Zh1iMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue?macro=true";
import { default as indexD248njDvpBMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/[id]/index.vue?macro=true";
import { default as _91versionNumber_93ReeMwefyfpMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue?macro=true";
import { default as indexgyNseDWEUIMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue?macro=true";
import { default as indexpF7DmVUvBxMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/index.vue?macro=true";
import { default as newQ1sgMiqpckMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/new.vue?macro=true";
import { default as admin3ZRjoL7FcXMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin.vue?macro=true";
import { default as signinw4e6s4YW67Meta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/assistants/[id]/signin.vue?macro=true";
import { default as callbackCpBKWzmHwPMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/auth/callback.vue?macro=true";
import { default as callback206ytWsqeA3Meta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/auth/callback2.vue?macro=true";
import { default as googleCallback2RkNYMqAdPnMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/auth/googleCallback2.vue?macro=true";
import { default as verify_emailtNwDT0vQlBMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/auth/verify_email.vue?macro=true";
import { default as index4LTtI2eKhvMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/index.vue?macro=true";
import { default as indexkq55FW7oZQMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/mytickets/index.vue?macro=true";
import { default as newFJisQS8loyMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/mytickets/new.vue?macro=true";
import { default as indexLlbPGSRFblMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/notifications/index.vue?macro=true";
import { default as indexp2fvwMInDYMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/profile/index.vue?macro=true";
import { default as index2ghX4mCgDAMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/security/index.vue?macro=true";
import { default as cloudsignfSPCyjrH8zMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue?macro=true";
import { default as googleChatr24vLxueN6Meta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/settings/googleChat.vue?macro=true";
import { default as notificationsm5x86eJy2xMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/settings/notifications.vue?macro=true";
import { default as tokensLjbgvvcmvNMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/settings/tokens.vue?macro=true";
import { default as indexKrKPzGiArmMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tasks/index.vue?macro=true";
import { default as editmHKMVclYuHMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue?macro=true";
import { default as editByApproverAjS1unoXgCMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue?macro=true";
import { default as indexe4R0C2qjjKMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue?macro=true";
import { default as printZJfDiiTyURMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue?macro=true";
import { default as indextKfJDgPJiDMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tickets/index.vue?macro=true";
import { default as indexWAtZZwyvJTMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/view/index.vue?macro=true";
import { default as edit66n8G190E2Meta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue?macro=true";
import { default as indexNiYvYTjCDGMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/view/settings/index.vue?macro=true";
import { default as new5A4Kdee0jiMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/view/settings/new.vue?macro=true";
import { default as searchi2jpScDH6VMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/workflows/search.vue?macro=true";
import { default as dashboardPnA0lMD5GwMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard.vue?macro=true";
import { default as expiredQkIiWj7VqbMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/expired.vue?macro=true";
import { default as indexTwd3Cl8Q4sMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/files/[id]/index.vue?macro=true";
import { default as indexiJ07DrSSdeMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/index.vue?macro=true";
import { default as _91id_93EKYEn1UQiXMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/invitations/[id].vue?macro=true";
import { default as misocaJCmcHcOgiEMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/misoca.vue?macro=true";
import { default as redirectyBeoxt2hCKMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/redirect.vue?macro=true";
import { default as selectq7iklavlDgMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/select.vue?macro=true";
import { default as signin4J344taFirMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/signin.vue?macro=true";
import { default as startSsoXYo4QsRPpJMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/startSso.vue?macro=true";
import { default as welcomeUtTcYZbLBYMeta } from "/tmp/build_e2e4ed4e/frontend_nuxt3/pages/welcome.vue?macro=true";
export default [
  {
    name: admin3ZRjoL7FcXMeta?.name,
    path: "/en/admin",
    meta: admin3ZRjoL7FcXMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-audit___en",
    path: "audit",
    meta: indexB1Ls7uui7CMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/audit/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-authentication___en",
    path: "authentication",
    meta: indexkTjbOOQmWYMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/authentication/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-automations-id-edit___en",
    path: "automations/:id()/edit",
    meta: edit1fKU0tNGuVMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/automations/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-automations___en",
    path: "automations",
    meta: indexVL51BGUi9kMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/automations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-automations-new___en",
    path: "automations/new",
    meta: newg549uRqowhMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/automations/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-billing___en",
    path: "billing",
    meta: indexnhW8jHRnfHMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-calculationVariables___en",
    path: "calculationVariables",
    meta: indexwJaJYSMzJCMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/calculationVariables/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-categories___en",
    path: "categories",
    meta: indexkp92OSd7mSMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-box-callback___en",
    path: "connections/box/callback",
    meta: callbackT418WrUPvYMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/box/callback.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-box___en",
    path: "connections/box",
    meta: index3nm8nTxeUfMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/box/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-box-redirect___en",
    path: "connections/box/redirect",
    meta: redirectZBGughc8toMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/box/redirect.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-chatwork___en",
    path: "connections/chatwork",
    meta: index3tmt25ijZAMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/chatwork/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-climberCloud___en",
    path: "connections/climberCloud",
    meta: indexiSkRGT7eM9Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-googleDrive___en",
    path: "connections/googleDrive",
    meta: indexJSTp5eeNVEMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-gwsDirectory___en",
    path: "connections/gwsDirectory",
    meta: indexlaoaajRM5YMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-gwsDirectory-results___en",
    path: "connections/gwsDirectory/results",
    meta: resultsiebPSh406bMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-lineWorks___en",
    path: "connections/lineWorks",
    meta: index9XWIwtdUkZMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-microsoftTeams-callback___en",
    path: "connections/microsoftTeams/callback",
    meta: callbackteJGZy7fHEMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-microsoftTeams___en",
    path: "connections/microsoftTeams",
    meta: indexACBu6cApJ7Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-serviceAccounts___en",
    path: "connections/serviceAccounts",
    meta: index4Vjo89f8mtMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-slack-callback___en",
    path: "connections/slack/callback",
    meta: callback4jG4cld0UcMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/slack/callback.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-slack___en",
    path: "connections/slack",
    meta: indexj6vdpo8msuMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/slack/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-slack-redirect___en",
    path: "connections/slack/redirect",
    meta: redirectHa4XNqqJzyMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/slack/redirect.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-webhook-id___en",
    path: "connections/webhook/:id()",
    meta: _91id_93lGPNLrvTLbMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/webhook/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-webhook___en",
    path: "connections/webhook",
    meta: indexk163EfVUIrMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/webhook/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-folders-id___en",
    path: "folders/:id()",
    meta: _91id_93YHEG6ZCFviMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/folders/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-folders___en",
    path: "folders",
    meta: index3N1LdRfcqYMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/folders/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-grades___en",
    path: "grades",
    meta: indexkGZnTE6GlSMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/grades/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "",
    meta: indexY3yUDuA8XMMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-invitationDomains___en",
    path: "invitationDomains",
    meta: indexEY6Tl4vQ66Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/invitationDomains/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-invitationSetting___en",
    path: "invitationSetting",
    meta: index3xXuO6J53fMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/invitationSetting/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-ip___en",
    path: "ip",
    meta: indexDaQl5fSANvMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/ip/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-labels___en",
    path: "labels",
    meta: indexEkPwHaFBw3Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/labels/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-masters-id___en",
    path: "masters/:id()",
    meta: indexXrynbCrqGRMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/masters/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-masters-id-workflows___en",
    path: "masters/:id()/workflows",
    meta: workflowsWpiZ1ldIlMMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue").then(m => m.default || m)
  },
  {
    name: "admin-masters___en",
    path: "masters",
    meta: indexFInJ2VeuyTMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/masters/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations-org-compare___en",
    path: "organizations/:org()/compare",
    meta: indexo7AmhY6k19Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations-org-csv___en",
    path: "organizations/:org()/csv",
    meta: csv1GpLkoQyd0Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations-org___en",
    path: "organizations/:org()",
    meta: index49RfxURyCzMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/[org]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations-org-teams-id___en",
    path: "organizations/:org()/teams/:id()",
    meta: _91id_93AVzKQudp3OMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations-history___en",
    path: "organizations/history",
    meta: historycBAFLRdYlqMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/history.vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations___en",
    path: "organizations",
    meta: indexCDv0ajEoJqMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-pipelines-id___en",
    path: "pipelines/:id()",
    meta: _91id_938uyj4oHXGTMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/pipelines/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-pipelines___en",
    path: "pipelines",
    meta: index0Q2Lkz6qDDMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/pipelines/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-pipelines-new___en",
    path: "pipelines/new",
    meta: newr59VjZ63e6Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/pipelines/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-proxyApplicants___en",
    path: "proxyApplicants",
    meta: indextqX8PNvjXRMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/proxyApplicants/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-proxyApprovers___en",
    path: "proxyApprovers",
    meta: indexLOQHwM4NWZMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/proxyApprovers/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-roles-id___en",
    path: "roles/:id()",
    meta: _91id_93MnctPi8vYxMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/roles/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-roles___en",
    path: "roles",
    meta: indexZy1VUYjTBrMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/roles/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-routes-id-edit___en",
    path: "routes/:id()/edit",
    meta: editKBUOfEnhYbMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-routes-id___en",
    path: "routes/:id()",
    meta: indexzOhMsjgjoEMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-routes-id-simulator___en",
    path: "routes/:id()/simulator",
    meta: index2GylVtcsg5Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-routes-id-versions-versionNumber___en",
    path: "routes/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93XdUE1h7oC8Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue").then(m => m.default || m)
  },
  {
    name: "admin-routes-id-versions___en",
    path: "routes/:id()/versions",
    meta: index6cj6GUVBVVMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-routes___en",
    path: "routes",
    meta: indexIVkuy9G4lNMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-routes-new___en",
    path: "routes/new",
    meta: newfysGQfVOSPMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-stats___en",
    path: "stats",
    meta: indexb3DBQO2jSUMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/stats/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-subStatuses___en",
    path: "subStatuses",
    meta: index6OSAHVILbnMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/subStatuses/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-assistants___en",
    path: "tenant/assistants",
    meta: assistants8dFx9MrfQeMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/assistants.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-backups___en",
    path: "tenant/backups",
    meta: backupsUi4KWaIJ4UMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/backups.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant___en",
    path: "tenant",
    meta: indexbbV8y57rZOMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-news___en",
    path: "tenant/news",
    meta: newsPaE4Qu0TeOMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/news.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-notifications___en",
    path: "tenant/notifications",
    meta: notificationsP9sLEYzmMlMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/notifications.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-remind___en",
    path: "tenant/remind",
    meta: remindSr09BYuFy3Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/remind.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-settings___en",
    path: "tenant/settings",
    meta: settingsSU2ZQV7TmiMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/settings.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-subdomain___en",
    path: "tenant/subdomain",
    meta: subdomainTz4ytuHfSmMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/subdomain.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-working___en",
    path: "tenant/working",
    meta: workingIJKvnCYKQPMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/working.vue").then(m => m.default || m)
  },
  {
    name: "admin-ticketNumberKeys-id-workflows___en",
    path: "ticketNumberKeys/:id()/workflows",
    meta: workflows9hRyktLkNNMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/ticketNumberKeys/[id]/workflows.vue").then(m => m.default || m)
  },
  {
    name: "admin-ticketNumberKeys___en",
    path: "ticketNumberKeys",
    meta: indexXGSAmtrhapMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/ticketNumberKeys/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-tickets-clean___en",
    path: "tickets/clean",
    meta: clean12bLxlUwexMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tickets/clean.vue").then(m => m.default || m)
  },
  {
    name: "admin-tickets-transfer-myticket___en",
    path: "tickets/transfer/myticket",
    meta: myticketb2OZjdGIm7Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue").then(m => m.default || m)
  },
  {
    name: "admin-tickets-transfer-task___en",
    path: "tickets/transfer/task",
    meta: taska7Ggvb9DwWMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tickets/transfer/task.vue").then(m => m.default || m)
  },
  {
    name: "admin-tickets-viewer___en",
    path: "tickets/viewer",
    meta: viewerD8I2qVG1qOMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tickets/viewer.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id___en",
    path: "users/:id()",
    meta: _91id_93rMyjffZ83DMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-users-csv___en",
    path: "users/csv",
    meta: csv7D3IwlQGmYMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/users/csv.vue").then(m => m.default || m)
  },
  {
    name: "admin-users___en",
    path: "users",
    meta: index4CqJ5U59FkMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-invite___en",
    path: "users/invite",
    meta: inviteEmnvjGzvUWMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/users/invite.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-unaffiliated___en",
    path: "users/unaffiliated",
    meta: unaffiliatedfKbULkhqPTMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/users/unaffiliated.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflows-id-edit___en",
    path: "workflows/:id()/edit",
    meta: editB5i6l5Zh1iMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflows-id___en",
    path: "workflows/:id()",
    meta: indexD248njDvpBMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflows-id-versions-versionNumber___en",
    path: "workflows/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93ReeMwefyfpMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue").then(m => m.default || m)
  },
  {
    name: "admin-workflows-id-versions___en",
    path: "workflows/:id()/versions",
    meta: indexgyNseDWEUIMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflows___en",
    path: "workflows",
    meta: indexpF7DmVUvBxMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflows-new___en",
    path: "workflows/new",
    meta: newQ1sgMiqpckMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: admin3ZRjoL7FcXMeta?.name,
    path: "/admin",
    meta: admin3ZRjoL7FcXMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-audit___ja",
    path: "audit",
    meta: indexB1Ls7uui7CMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/audit/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-authentication___ja",
    path: "authentication",
    meta: indexkTjbOOQmWYMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/authentication/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-automations-id-edit___ja",
    path: "automations/:id()/edit",
    meta: edit1fKU0tNGuVMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/automations/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-automations___ja",
    path: "automations",
    meta: indexVL51BGUi9kMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/automations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-automations-new___ja",
    path: "automations/new",
    meta: newg549uRqowhMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/automations/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-billing___ja",
    path: "billing",
    meta: indexnhW8jHRnfHMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-calculationVariables___ja",
    path: "calculationVariables",
    meta: indexwJaJYSMzJCMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/calculationVariables/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-categories___ja",
    path: "categories",
    meta: indexkp92OSd7mSMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-box-callback___ja",
    path: "connections/box/callback",
    meta: callbackT418WrUPvYMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/box/callback.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-box___ja",
    path: "connections/box",
    meta: index3nm8nTxeUfMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/box/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-box-redirect___ja",
    path: "connections/box/redirect",
    meta: redirectZBGughc8toMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/box/redirect.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-chatwork___ja",
    path: "connections/chatwork",
    meta: index3tmt25ijZAMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/chatwork/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-climberCloud___ja",
    path: "connections/climberCloud",
    meta: indexiSkRGT7eM9Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-googleDrive___ja",
    path: "connections/googleDrive",
    meta: indexJSTp5eeNVEMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-gwsDirectory___ja",
    path: "connections/gwsDirectory",
    meta: indexlaoaajRM5YMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-gwsDirectory-results___ja",
    path: "connections/gwsDirectory/results",
    meta: resultsiebPSh406bMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-lineWorks___ja",
    path: "connections/lineWorks",
    meta: index9XWIwtdUkZMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-microsoftTeams-callback___ja",
    path: "connections/microsoftTeams/callback",
    meta: callbackteJGZy7fHEMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-microsoftTeams___ja",
    path: "connections/microsoftTeams",
    meta: indexACBu6cApJ7Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-serviceAccounts___ja",
    path: "connections/serviceAccounts",
    meta: index4Vjo89f8mtMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-slack-callback___ja",
    path: "connections/slack/callback",
    meta: callback4jG4cld0UcMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/slack/callback.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-slack___ja",
    path: "connections/slack",
    meta: indexj6vdpo8msuMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/slack/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-slack-redirect___ja",
    path: "connections/slack/redirect",
    meta: redirectHa4XNqqJzyMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/slack/redirect.vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-webhook-id___ja",
    path: "connections/webhook/:id()",
    meta: _91id_93lGPNLrvTLbMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/webhook/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-connections-webhook___ja",
    path: "connections/webhook",
    meta: indexk163EfVUIrMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/connections/webhook/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-folders-id___ja",
    path: "folders/:id()",
    meta: _91id_93YHEG6ZCFviMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/folders/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-folders___ja",
    path: "folders",
    meta: index3N1LdRfcqYMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/folders/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-grades___ja",
    path: "grades",
    meta: indexkGZnTE6GlSMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/grades/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___ja",
    path: "",
    meta: indexY3yUDuA8XMMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-invitationDomains___ja",
    path: "invitationDomains",
    meta: indexEY6Tl4vQ66Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/invitationDomains/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-invitationSetting___ja",
    path: "invitationSetting",
    meta: index3xXuO6J53fMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/invitationSetting/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-ip___ja",
    path: "ip",
    meta: indexDaQl5fSANvMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/ip/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-labels___ja",
    path: "labels",
    meta: indexEkPwHaFBw3Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/labels/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-masters-id___ja",
    path: "masters/:id()",
    meta: indexXrynbCrqGRMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/masters/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-masters-id-workflows___ja",
    path: "masters/:id()/workflows",
    meta: workflowsWpiZ1ldIlMMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue").then(m => m.default || m)
  },
  {
    name: "admin-masters___ja",
    path: "masters",
    meta: indexFInJ2VeuyTMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/masters/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations-org-compare___ja",
    path: "organizations/:org()/compare",
    meta: indexo7AmhY6k19Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations-org-csv___ja",
    path: "organizations/:org()/csv",
    meta: csv1GpLkoQyd0Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations-org___ja",
    path: "organizations/:org()",
    meta: index49RfxURyCzMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/[org]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations-org-teams-id___ja",
    path: "organizations/:org()/teams/:id()",
    meta: _91id_93AVzKQudp3OMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations-history___ja",
    path: "organizations/history",
    meta: historycBAFLRdYlqMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/history.vue").then(m => m.default || m)
  },
  {
    name: "admin-organizations___ja",
    path: "organizations",
    meta: indexCDv0ajEoJqMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-pipelines-id___ja",
    path: "pipelines/:id()",
    meta: _91id_938uyj4oHXGTMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/pipelines/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-pipelines___ja",
    path: "pipelines",
    meta: index0Q2Lkz6qDDMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/pipelines/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-pipelines-new___ja",
    path: "pipelines/new",
    meta: newr59VjZ63e6Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/pipelines/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-proxyApplicants___ja",
    path: "proxyApplicants",
    meta: indextqX8PNvjXRMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/proxyApplicants/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-proxyApprovers___ja",
    path: "proxyApprovers",
    meta: indexLOQHwM4NWZMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/proxyApprovers/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-roles-id___ja",
    path: "roles/:id()",
    meta: _91id_93MnctPi8vYxMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/roles/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-roles___ja",
    path: "roles",
    meta: indexZy1VUYjTBrMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/roles/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-routes-id-edit___ja",
    path: "routes/:id()/edit",
    meta: editKBUOfEnhYbMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-routes-id___ja",
    path: "routes/:id()",
    meta: indexzOhMsjgjoEMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-routes-id-simulator___ja",
    path: "routes/:id()/simulator",
    meta: index2GylVtcsg5Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-routes-id-versions-versionNumber___ja",
    path: "routes/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93XdUE1h7oC8Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue").then(m => m.default || m)
  },
  {
    name: "admin-routes-id-versions___ja",
    path: "routes/:id()/versions",
    meta: index6cj6GUVBVVMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-routes___ja",
    path: "routes",
    meta: indexIVkuy9G4lNMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-routes-new___ja",
    path: "routes/new",
    meta: newfysGQfVOSPMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/routes/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-stats___ja",
    path: "stats",
    meta: indexb3DBQO2jSUMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/stats/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-subStatuses___ja",
    path: "subStatuses",
    meta: index6OSAHVILbnMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/subStatuses/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-assistants___ja",
    path: "tenant/assistants",
    meta: assistants8dFx9MrfQeMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/assistants.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-backups___ja",
    path: "tenant/backups",
    meta: backupsUi4KWaIJ4UMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/backups.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant___ja",
    path: "tenant",
    meta: indexbbV8y57rZOMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-news___ja",
    path: "tenant/news",
    meta: newsPaE4Qu0TeOMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/news.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-notifications___ja",
    path: "tenant/notifications",
    meta: notificationsP9sLEYzmMlMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/notifications.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-remind___ja",
    path: "tenant/remind",
    meta: remindSr09BYuFy3Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/remind.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-settings___ja",
    path: "tenant/settings",
    meta: settingsSU2ZQV7TmiMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/settings.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-subdomain___ja",
    path: "tenant/subdomain",
    meta: subdomainTz4ytuHfSmMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/subdomain.vue").then(m => m.default || m)
  },
  {
    name: "admin-tenant-working___ja",
    path: "tenant/working",
    meta: workingIJKvnCYKQPMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tenant/working.vue").then(m => m.default || m)
  },
  {
    name: "admin-ticketNumberKeys-id-workflows___ja",
    path: "ticketNumberKeys/:id()/workflows",
    meta: workflows9hRyktLkNNMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/ticketNumberKeys/[id]/workflows.vue").then(m => m.default || m)
  },
  {
    name: "admin-ticketNumberKeys___ja",
    path: "ticketNumberKeys",
    meta: indexXGSAmtrhapMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/ticketNumberKeys/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-tickets-clean___ja",
    path: "tickets/clean",
    meta: clean12bLxlUwexMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tickets/clean.vue").then(m => m.default || m)
  },
  {
    name: "admin-tickets-transfer-myticket___ja",
    path: "tickets/transfer/myticket",
    meta: myticketb2OZjdGIm7Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue").then(m => m.default || m)
  },
  {
    name: "admin-tickets-transfer-task___ja",
    path: "tickets/transfer/task",
    meta: taska7Ggvb9DwWMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tickets/transfer/task.vue").then(m => m.default || m)
  },
  {
    name: "admin-tickets-viewer___ja",
    path: "tickets/viewer",
    meta: viewerD8I2qVG1qOMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/tickets/viewer.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id___ja",
    path: "users/:id()",
    meta: _91id_93rMyjffZ83DMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-users-csv___ja",
    path: "users/csv",
    meta: csv7D3IwlQGmYMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/users/csv.vue").then(m => m.default || m)
  },
  {
    name: "admin-users___ja",
    path: "users",
    meta: index4CqJ5U59FkMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-invite___ja",
    path: "users/invite",
    meta: inviteEmnvjGzvUWMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/users/invite.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-unaffiliated___ja",
    path: "users/unaffiliated",
    meta: unaffiliatedfKbULkhqPTMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/users/unaffiliated.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflows-id-edit___ja",
    path: "workflows/:id()/edit",
    meta: editB5i6l5Zh1iMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflows-id___ja",
    path: "workflows/:id()",
    meta: indexD248njDvpBMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflows-id-versions-versionNumber___ja",
    path: "workflows/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93ReeMwefyfpMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue").then(m => m.default || m)
  },
  {
    name: "admin-workflows-id-versions___ja",
    path: "workflows/:id()/versions",
    meta: indexgyNseDWEUIMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflows___ja",
    path: "workflows",
    meta: indexpF7DmVUvBxMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflows-new___ja",
    path: "workflows/new",
    meta: newQ1sgMiqpckMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/admin/workflows/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "assistants-id-signin___en",
    path: "/en/assistants/:id()/signin",
    meta: signinw4e6s4YW67Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/assistants/[id]/signin.vue").then(m => m.default || m)
  },
  {
    name: "assistants-id-signin___ja",
    path: "/assistants/:id()/signin",
    meta: signinw4e6s4YW67Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/assistants/[id]/signin.vue").then(m => m.default || m)
  },
  {
    name: "auth-callback___en",
    path: "/en/auth/callback",
    meta: callbackCpBKWzmHwPMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/auth/callback.vue").then(m => m.default || m)
  },
  {
    name: "auth-callback___ja",
    path: "/auth/callback",
    meta: callbackCpBKWzmHwPMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/auth/callback.vue").then(m => m.default || m)
  },
  {
    name: "auth-callback2___en",
    path: "/en/auth/callback2",
    meta: callback206ytWsqeA3Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/auth/callback2.vue").then(m => m.default || m)
  },
  {
    name: "auth-callback2___ja",
    path: "/auth/callback2",
    meta: callback206ytWsqeA3Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/auth/callback2.vue").then(m => m.default || m)
  },
  {
    name: "auth-googleCallback2___en",
    path: "/en/auth/googleCallback2",
    meta: googleCallback2RkNYMqAdPnMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/auth/googleCallback2.vue").then(m => m.default || m)
  },
  {
    name: "auth-googleCallback2___ja",
    path: "/auth/googleCallback2",
    meta: googleCallback2RkNYMqAdPnMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/auth/googleCallback2.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify_email___en",
    path: "/en/auth/verify_email",
    meta: verify_emailtNwDT0vQlBMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/auth/verify_email.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify_email___ja",
    path: "/auth/verify_email",
    meta: verify_emailtNwDT0vQlBMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/auth/verify_email.vue").then(m => m.default || m)
  },
  {
    name: dashboardPnA0lMD5GwMeta?.name,
    path: "/en/dashboard",
    meta: dashboardPnA0lMD5GwMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard___en",
    path: "",
    meta: index4LTtI2eKhvMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-mytickets___en",
    path: "mytickets",
    meta: indexkq55FW7oZQMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/mytickets/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-mytickets-new___en",
    path: "mytickets/new",
    meta: newFJisQS8loyMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/mytickets/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-notifications___en",
    path: "notifications",
    meta: indexLlbPGSRFblMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile___en",
    path: "profile",
    meta: indexp2fvwMInDYMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-security___en",
    path: "security",
    meta: index2ghX4mCgDAMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/security/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-cloudsign___en",
    path: "settings/cloudsign",
    meta: cloudsignfSPCyjrH8zMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-googleChat___en",
    path: "settings/googleChat",
    meta: googleChatr24vLxueN6Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/settings/googleChat.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-notifications___en",
    path: "settings/notifications",
    meta: notificationsm5x86eJy2xMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-tokens___en",
    path: "settings/tokens",
    meta: tokensLjbgvvcmvNMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/settings/tokens.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tasks___en",
    path: "tasks",
    meta: indexKrKPzGiArmMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tickets-id-edit___en",
    path: "tickets/:id()/edit",
    meta: editmHKMVclYuHMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tickets-id-editByApprover___en",
    path: "tickets/:id()/editByApprover",
    meta: editByApproverAjS1unoXgCMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tickets-id___en",
    path: "tickets/:id()",
    meta: indexe4R0C2qjjKMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tickets-id-print___en",
    path: "tickets/:id()/print",
    meta: printZJfDiiTyURMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tickets___en",
    path: "tickets",
    meta: indextKfJDgPJiDMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-view___en",
    path: "view",
    meta: indexWAtZZwyvJTMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/view/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-view-settings-id-edit___en",
    path: "view/settings/:id()/edit",
    meta: edit66n8G190E2Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-view-settings___en",
    path: "view/settings",
    meta: indexNiYvYTjCDGMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/view/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-view-settings-new___en",
    path: "view/settings/new",
    meta: new5A4Kdee0jiMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/view/settings/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-workflows-search___en",
    path: "workflows/search",
    meta: searchi2jpScDH6VMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/workflows/search.vue").then(m => m.default || m)
  }
]
  },
  {
    name: dashboardPnA0lMD5GwMeta?.name,
    path: "/dashboard",
    meta: dashboardPnA0lMD5GwMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard___ja",
    path: "",
    meta: index4LTtI2eKhvMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-mytickets___ja",
    path: "mytickets",
    meta: indexkq55FW7oZQMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/mytickets/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-mytickets-new___ja",
    path: "mytickets/new",
    meta: newFJisQS8loyMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/mytickets/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-notifications___ja",
    path: "notifications",
    meta: indexLlbPGSRFblMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile___ja",
    path: "profile",
    meta: indexp2fvwMInDYMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-security___ja",
    path: "security",
    meta: index2ghX4mCgDAMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/security/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-cloudsign___ja",
    path: "settings/cloudsign",
    meta: cloudsignfSPCyjrH8zMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-googleChat___ja",
    path: "settings/googleChat",
    meta: googleChatr24vLxueN6Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/settings/googleChat.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-notifications___ja",
    path: "settings/notifications",
    meta: notificationsm5x86eJy2xMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-tokens___ja",
    path: "settings/tokens",
    meta: tokensLjbgvvcmvNMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/settings/tokens.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tasks___ja",
    path: "tasks",
    meta: indexKrKPzGiArmMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tickets-id-edit___ja",
    path: "tickets/:id()/edit",
    meta: editmHKMVclYuHMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tickets-id-editByApprover___ja",
    path: "tickets/:id()/editByApprover",
    meta: editByApproverAjS1unoXgCMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tickets-id___ja",
    path: "tickets/:id()",
    meta: indexe4R0C2qjjKMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tickets-id-print___ja",
    path: "tickets/:id()/print",
    meta: printZJfDiiTyURMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tickets___ja",
    path: "tickets",
    meta: indextKfJDgPJiDMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-view___ja",
    path: "view",
    meta: indexWAtZZwyvJTMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/view/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-view-settings-id-edit___ja",
    path: "view/settings/:id()/edit",
    meta: edit66n8G190E2Meta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-view-settings___ja",
    path: "view/settings",
    meta: indexNiYvYTjCDGMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/view/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-view-settings-new___ja",
    path: "view/settings/new",
    meta: new5A4Kdee0jiMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/view/settings/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-workflows-search___ja",
    path: "workflows/search",
    meta: searchi2jpScDH6VMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/dashboard/workflows/search.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "expired___en",
    path: "/en/expired",
    meta: expiredQkIiWj7VqbMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/expired.vue").then(m => m.default || m)
  },
  {
    name: "expired___ja",
    path: "/expired",
    meta: expiredQkIiWj7VqbMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/expired.vue").then(m => m.default || m)
  },
  {
    name: "files-id___en",
    path: "/en/files/:id()",
    meta: indexTwd3Cl8Q4sMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/files/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "files-id___ja",
    path: "/files/:id()",
    meta: indexTwd3Cl8Q4sMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/files/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexiJ07DrSSdeMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ja",
    path: "/",
    meta: indexiJ07DrSSdeMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invitations-id___en",
    path: "/en/invitations/:id()",
    meta: _91id_93EKYEn1UQiXMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/invitations/[id].vue").then(m => m.default || m)
  },
  {
    name: "invitations-id___ja",
    path: "/invitations/:id()",
    meta: _91id_93EKYEn1UQiXMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/invitations/[id].vue").then(m => m.default || m)
  },
  {
    name: "misoca___en",
    path: "/en/misoca",
    meta: misocaJCmcHcOgiEMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/misoca.vue").then(m => m.default || m)
  },
  {
    name: "misoca___ja",
    path: "/misoca",
    meta: misocaJCmcHcOgiEMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/misoca.vue").then(m => m.default || m)
  },
  {
    name: "redirect___en",
    path: "/en/redirect",
    meta: redirectyBeoxt2hCKMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: "redirect___ja",
    path: "/redirect",
    meta: redirectyBeoxt2hCKMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: "select___en",
    path: "/en/select",
    meta: selectq7iklavlDgMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/select.vue").then(m => m.default || m)
  },
  {
    name: "select___ja",
    path: "/select",
    meta: selectq7iklavlDgMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/select.vue").then(m => m.default || m)
  },
  {
    name: "signin___en",
    path: "/en/signin",
    meta: signin4J344taFirMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signin___ja",
    path: "/signin",
    meta: signin4J344taFirMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "startSso___en",
    path: "/en/startSso",
    meta: startSsoXYo4QsRPpJMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/startSso.vue").then(m => m.default || m)
  },
  {
    name: "startSso___ja",
    path: "/startSso",
    meta: startSsoXYo4QsRPpJMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/startSso.vue").then(m => m.default || m)
  },
  {
    name: "welcome___en",
    path: "/en/welcome",
    meta: welcomeUtTcYZbLBYMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/welcome.vue").then(m => m.default || m)
  },
  {
    name: "welcome___ja",
    path: "/welcome",
    meta: welcomeUtTcYZbLBYMeta || {},
    component: () => import("/tmp/build_e2e4ed4e/frontend_nuxt3/pages/welcome.vue").then(m => m.default || m)
  }
]